import React, { useState } from 'react'

import Note from './Note'
import Notes from './Notes'

export interface INotesTabProps {
  user: MainSchema.CommunityUser
}

// TODO: find a better replacement for this temporary "router"
function NotesTab({ user }: INotesTabProps) {
  const [showNoteForm, setShowNoteForm] = useState(false)
  const [note, setNote] = useState<MainSchema.Note | undefined>(undefined)

  if (showNoteForm || note) {
    return (
      <Note
        noteId={note?.id}
        userId={user.userId}
        onBack={() => {
          setShowNoteForm(false)
          setNote(undefined)
        }}
      />
    )
  }

  return (
    <Notes
      user={user}
      onClickCreate={() => {
        setShowNoteForm(true)
        setNote(undefined)
      }}
      onClickNote={note => {
        setShowNoteForm(false)
        setNote(note)
      }}
    />
  )
}

export default NotesTab

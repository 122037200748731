import React, {
  MouseEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'

import { useMutation } from '@apollo/client'
import { IconLock, IconShare, IconStar, IconTrash } from '@tabler/icons-react'
import useAbility from 'Features/Notes/useAbility'
import updateNoteFavoriteMutation from 'GraphQL/Mutations/User/updateNoteFavorite.graphql'
import notesQuery from 'GraphQL/Queries/Notes/notes.graphql'
import { DateTime } from 'luxon'
import stringReplacer from 'Utils/stringReplacer'

import map from 'lodash/map'

import DeleteNotesModal, { INote } from 'Components/Blocks/Modals/DeleteNotes'
import ShareNoteModal from 'Components/Blocks/Modals/ShareNote'
import Dropdown, {
  IOption,
} from 'Components/Blocks/QuickActions/ActionOptions/Dropdown'
import {
  Box,
  Button,
  Column,
  Loader,
  Pagination,
  Row,
  Switch,
  Tooltip,
} from 'Components/UI'
import { Text } from 'Components/UI/_v2'

import { SortInputOrder } from 'Constants/mainGraphQL'
import { notesReplacers } from 'Constants/replacers'

import { useCommunity, useEntityModal, usePagination } from 'Hooks'

import { useQuery } from 'Services/Apollo'
import EventBus from 'Services/EventBus'
import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import colors from 'Theme/_v2/colors'

import { Container, Note, ScrollableContainer } from './styles'

const SORT_BY = [
  {
    column: 'updatedAt',
    order: SortInputOrder.Desc,
  },
]

export interface INotesProps {
  user: MainSchema.CommunityUser
  onClickCreate: React.MouseEventHandler<HTMLButtonElement>
  onClickNote: (note: MainSchema.Note) => void
}

function Notes({ user, onClickCreate, onClickNote }: INotesProps) {
  const mostRecentPage = useRef(0)
  const mostRecentLimit = useRef(10)

  const s = useScopedI18n('notesManagement')
  const { community } = useCommunity()
  const { canCreate, canEdit, canDelete, canBeShared } = useAbility()
  const [deleteNoteModal, deleteNoteModalActions] = useEntityModal<INote>()
  const [shareNoteModal, shareNoteModalActions] = useEntityModal<string>()

  const [showOnlyOwn, setShowOnlyOwn] = useState(true)

  const [updateNoteFavorite] = useMutation(updateNoteFavoriteMutation)

  const communityId = community?.id

  const { data, loading, refetch } = useQuery<
    Pick<MainSchema.Query, 'notes'>,
    MainSchema.QueryNotesArgs
  >(notesQuery, {
    skip: !user || !communityId,
    variables:
      user && communityId
        ? {
            communityId,
            userId: user.userId,
            page: mostRecentPage.current,
            limit: mostRecentLimit.current,
            sort: SORT_BY,
            showOnlyOwn,
          }
        : undefined,
    fetchPolicy: 'cache-and-network',
  })

  const notes = useMemo(() => data?.notes?.rows || [], [data])

  const entities: MainSchema.NoteList | undefined = useMemo(
    () => data?.notes,
    [data],
  )

  const [paginationProps] = usePagination({ data: entities })

  const { pageSize, pageIndex } = paginationProps.state
  mostRecentPage.current = pageIndex
  mostRecentLimit.current = pageSize

  const handleGotoPreviousPage = useCallback(() => {
    mostRecentPage.current -= 1
    paginationProps?.previousPage()
  }, [paginationProps])

  const handleGotoNextPage = useCallback(() => {
    mostRecentPage.current += 1
    paginationProps?.nextPage()
  }, [paginationProps])

  const handleGotoFirstPage = useCallback(() => {
    mostRecentPage.current = 0
    paginationProps.firstPage()
  }, [paginationProps])

  const handleGotoLastPage = useCallback(() => {
    mostRecentPage.current = entities?.pages ? entities.pages - 1 : 0
    paginationProps.lastPage()
  }, [entities, paginationProps])

  const handleNoteFavorite = useCallback(
    async (event: MouseEvent<HTMLButtonElement>, note: MainSchema.Note) => {
      event.stopPropagation()
      event.preventDefault()
      try {
        await updateNoteFavorite({
          variables: {
            noteId: note.id,
            favorite: !note.favorite,
            communityId,
          },
        })
      } catch (error) {
        let message = _(`error.generic`)

        if (error instanceof Error) {
          message = error.message
        }

        toast.error({
          title: 'Update note prioritization',
          text: message,
        })
      }
    },
    [updateNoteFavorite, communityId],
  )

  const handleShowMyNotes = useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(e => {
    setShowOnlyOwn(e.target.checked)
  }, [])

  const handleOpenShareNoteModal = useCallback(
    (event: MouseEvent<HTMLButtonElement>, noteId: string) => {
      event.stopPropagation()
      event.preventDefault()
      shareNoteModalActions.openModal(noteId)
    },
    [shareNoteModalActions],
  )

  const handleDeleteModal = useCallback(
    (event: MouseEvent<HTMLButtonElement>, note: MainSchema.Note) => {
      event.stopPropagation()
      event.preventDefault()
      deleteNoteModalActions.openModal({
        id: note.id,
        content: note.content,
      })
    },
    [deleteNoteModalActions],
  )

  const handleCloseShareNoteModal = () => {
    shareNoteModalActions.closeModal(true)

    refetch().then()
  }

  const handleCloseDeleteNoteModal = (success: boolean, noteIds: string[]) => {
    deleteNoteModalActions.closeModal(success)

    noteIds.forEach(noteId => {
      EventBus.trigger(EventBus.actions.dashboard.deleteNote, noteId)
    })

    refetch().then()
  }

  return (
    <>
      {loading ? (
        <Column center fullHeight fullWidth justifyCenter>
          <Loader />
        </Column>
      ) : (
        <Container mr="-12px">
          <Row center fullWidth gap={3} pr={3} spaceBetween>
            <Switch
              checked={showOnlyOwn}
              label={s('onlyMyNotes')}
              labelPosition="right"
              onChange={handleShowMyNotes}
            />

            {canCreate(user.userId) && (
              <Button padding={0} small onClick={onClickCreate}>
                {s('create')}
              </Button>
            )}
          </Row>

          <ScrollableContainer>
            {map(notes, note => {
              const dropdownOptions: IOption[] = []

              if (canEdit(note)) {
                dropdownOptions.push({
                  key: 'favorite',
                  icon: <IconStar stroke={1.2} />,
                  label: note.favorite
                    ? s('options.unfavorite')
                    : s('options.favorite'),
                  handleClick: event => {
                    handleNoteFavorite(event, note)
                  },
                })
              }

              if (canBeShared(note)) {
                dropdownOptions.push({
                  key: 'share',
                  icon: <IconShare stroke={1.2} />,
                  label: s('options.share'),
                  handleClick: event => {
                    handleOpenShareNoteModal(event, note.id)
                  },
                })
              }

              if (canDelete(note)) {
                dropdownOptions.push({
                  key: 'delete',
                  icon: <IconTrash stroke={1.2} />,
                  label: s('options.delete'),
                  handleClick: event => {
                    handleDeleteModal(event, note)
                  },
                })
              }

              return (
                <Note
                  id={note.id}
                  key={note.id}
                  onClick={() => onClickNote(note)}
                >
                  <Box position="absolute" right={1} top={1}>
                    <Dropdown options={dropdownOptions} />
                  </Box>

                  <Row center fullWidth spaceBetween>
                    <Row center gap={1}>
                      {!note.public && (
                        <Tooltip content={_('tips.note.isPrivate')}>
                          <IconLock
                            color={colors.icon.note.private}
                            size={18}
                            stroke={1}
                          />
                        </Tooltip>
                      )}

                      {note.favorite && (
                        <Tooltip content={_('tips.note.isFavorite')}>
                          <IconStar
                            color={colors.icon.note.favorite}
                            fill={colors.icon.note.favorite}
                            size={18}
                            stroke={1.2}
                          />
                        </Tooltip>
                      )}
                    </Row>
                  </Row>

                  <Text
                    color={colors.text.primary}
                    fontSize="12px"
                    fontWeight={400}
                    lineHeight="21px"
                  >
                    {stringReplacer(note.content, notesReplacers)}
                  </Text>

                  <Row center gap={3} spaceBetween>
                    <Text
                      color={colors.text.secondary}
                      fontSize="10px"
                      fontWeight={400}
                      lineHeight={1}
                    >
                      {DateTime.fromISO(note.updatedAt).toLocaleString({
                        month: 'long',
                        day: 'numeric',
                      })}
                    </Text>
                  </Row>
                </Note>
              )
            })}
          </ScrollableContainer>

          {paginationProps.total > 10 && (
            <Pagination
              canChangePageSize={false}
              loading={false}
              mobile
              mr={3}
              showTotal
              state={{ pageIndex, pageSize }}
              total={paginationProps.total}
              onFirstPage={handleGotoFirstPage}
              onLastPage={handleGotoLastPage}
              onNextPage={handleGotoNextPage}
              onPageIndexChange={undefined}
              onPageSizeChange={undefined}
              onPreviousPage={handleGotoPreviousPage}
            />
          )}
        </Container>
      )}

      <ShareNoteModal
        isOpen={shareNoteModal.isOpen}
        noteId={shareNoteModal.entity}
        onClose={handleCloseShareNoteModal}
      />

      <DeleteNotesModal
        isOpen={deleteNoteModal.isOpen}
        notes={deleteNoteModal.entity ? [deleteNoteModal.entity] : []}
        onClose={handleCloseDeleteNoteModal}
      />
    </>
  )
}

export default Notes

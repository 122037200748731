import React from 'react'

import disconnectUsersFromSkillsMutation from 'GraphQL/Mutations/Community/disconnectUsersFromSkills.graphql'
import disconnectUsersFromTagsMutation from 'GraphQL/Mutations/Community/disconnectUsersFromTags.graphql'
import { getCommunityUserSkillRemoveUpdater } from 'GraphQL/Updaters/GetCommunityUserSkills'
import { getCommunityUserTagsRemoveUpdater } from 'GraphQL/Updaters/GetCommunityUserTags'

import { ContactBox, TagBlock } from 'Components/Blocks/UserProfile/Blocks'
import { Column } from 'Components/UI'

import { SEARCH_TYPES } from 'Constants/ids'
import { TAG_BLOCK_KIND } from 'Constants/tags'

import { useCommunityContext } from 'Hooks'

import { useMutation } from 'Services/Apollo'
import EventBus from 'Services/EventBus'
import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import EducationHistoryBox from '../Blocks/EducationHistoryBox/EducationHistoryBox'
import WorkHistoryBox from '../Blocks/WorkHistoryBox/WorkHistoryBox'

export interface IOverviewTabProps {
  // TODO: Still need to support GraphUser for shared users
  user: MainSchema.GraphUser | MainSchema.CommunityUser
  sharedUserId?: string
  showCreateProposals?: boolean
}

function OverviewTab({
  user,
  sharedUserId,
  showCreateProposals = true,
}: IOverviewTabProps) {
  const { community } = useCommunityContext()
  const s = useScopedI18n('community')

  const [disconnectUsersFromSkills] = useMutation<
    Pick<MainSchema.Mutation, 'disconnectUsersFromSkills'>,
    MainSchema.MutationDisconnectUsersFromSkillsArgs
  >(disconnectUsersFromSkillsMutation)

  const [disconnectUsersFromTags] = useMutation<
    Pick<MainSchema.Mutation, 'disconnectUsersFromTags'>,
    MainSchema.MutationDisconnectUsersFromTagsArgs
  >(disconnectUsersFromTagsMutation)

  const skills = React.useMemo(() => user.skills, [user.skills])

  const projects = React.useMemo(
    () =>
      user.tags?.filter(
        (tag: MainSchema.Tag) => tag.kind === SEARCH_TYPES.project,
      ) ?? [],
    [user.tags],
  )

  const events = React.useMemo(
    () =>
      user.tags?.filter(
        (tag: MainSchema.Tag) => tag.kind === SEARCH_TYPES.event,
      ) ?? [],
    [user.tags],
  )

  const customTags = React.useMemo(
    () =>
      user.tags?.filter(
        (tag: MainSchema.Tag) => tag.kind === SEARCH_TYPES.custom,
      ) ?? [],
    [user.tags],
  )

  const groups = React.useMemo(
    () =>
      user.tags?.filter(
        (tag: MainSchema.Tag) => tag.kind === SEARCH_TYPES.group,
      ) ?? [],
    [user.tags],
  )

  const handleRemoveSkill = React.useCallback(
    async (skillId: string) => {
      try {
        if (!community?.id || !user.communityUserId) {
          return
        }

        await disconnectUsersFromSkills({
          variables: {
            communityId: community.id,
            usersFromSkills: [
              {
                communityUserId: user.communityUserId,
                skillId,
              },
            ],
          },
          update: getCommunityUserSkillRemoveUpdater({
            id: user.communityUserId,
            userId: user?.userId!,
            communityUserId: user.communityUserId,
            communityId: community.id,
            skillIds: [skillId],
          }),
        })

        EventBus.trigger(EventBus.actions.graph.disconnectSkillTag, {
          toId: user.userId,
          fromId: skillId,
        })

        toast.success({
          title: 'Remove tag',
          text: 'Tag successfully removed',
        })
      } catch (error) {
        let message = _(`error.generic`)

        if (error instanceof Error) {
          message = error.message
        }

        toast.error({
          title: 'Failed to Remove tag',
          text: message,
        })
      }
    },
    [
      community?.id,
      disconnectUsersFromSkills,
      user.communityUserId,
      user.userId,
    ],
  )

  const handleRemoveTag = React.useCallback(
    async (tagId: string) => {
      try {
        if (!community?.id || !user.communityUserId) {
          return
        }

        await disconnectUsersFromTags({
          variables: {
            communityId: community.id,
            usersFromTags: [
              {
                communityUserId: user.communityUserId,
                tagId,
              },
            ],
          },
          update: getCommunityUserTagsRemoveUpdater({
            id: user.communityUserId,
            userId: user?.userId!,
            communityUserId: user.communityUserId,
            communityId: community.id,
            tagIds: [tagId],
          }),
        })

        EventBus.trigger(EventBus.actions.graph.disconnectSkillTag, {
          toId: user.userId,
          fromId: tagId,
        })

        toast.success({
          title: s('communityUserTag.title'),
          text: s('communityUserTag.deleteSuccess'),
        })
      } catch (error) {
        let message = _(`error.generic`)

        if (error instanceof Error) {
          message = error.message
        }

        toast.success({
          title: s('communityUserTag.errorTitle'),
          text: message,
        })
      }
    },
    [
      community?.id,
      disconnectUsersFromTags,
      s,
      user.communityUserId,
      user.userId,
    ],
  )

  return (
    <Column fullWidth gap={2}>
      <ContactBox
        sharedUserId={sharedUserId}
        showCreateProposals={showCreateProposals}
        user={user}
      />

      <WorkHistoryBox communityUser={user} />

      <EducationHistoryBox communityUser={user} />

      <TagBlock
        kind={TAG_BLOCK_KIND.SKILL}
        tags={skills}
        targetUser={user}
        onRemove={handleRemoveSkill}
      />

      <TagBlock
        kind={TAG_BLOCK_KIND.EVENT}
        tags={events}
        targetUser={user}
        onRemove={handleRemoveTag}
      />

      <TagBlock
        kind={TAG_BLOCK_KIND.PROJECT}
        tags={projects}
        targetUser={user}
        onRemove={handleRemoveTag}
      />

      <TagBlock
        kind={TAG_BLOCK_KIND.GROUP}
        tags={groups}
        targetUser={user}
        onRemove={handleRemoveTag}
      />

      <TagBlock
        kind={TAG_BLOCK_KIND.CUSTOM}
        tags={customTags}
        targetUser={user}
        onRemove={handleRemoveTag}
      />
    </Column>
  )
}

export default OverviewTab

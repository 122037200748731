import { useCallback } from 'react'

import { useApolloClient } from '@apollo/client'
import communityPathToUserQuery from 'GraphQL/Queries/Community/communityPathToUser.graphql'
import communitySearchUsersQuery from 'GraphQL/Queries/Community/communitySearchUsers.graphql'
import communityUserConnectionsByDegreesQuery from 'GraphQL/Queries/Community/communityUserConnectionsByDegrees.graphql'
import listCommunityUsersQuery from 'GraphQL/Queries/CommunityUser/listCommunityUsers.min.graphql'
import getOrganizationQuery from 'GraphQL/Queries/Organization/getOrganization.graphql'
import PQueue from 'p-queue'

import isEmpty from 'lodash/isEmpty'
import keys from 'lodash/keys'

import { NodeKind } from 'Constants/graph'
import { SEARCH_TYPES } from 'Constants/ids'

import { useAppContext, useCommunityContext } from 'Hooks'
import useEventBusSubscribe from 'Hooks/useEventBusSubscribe'
import {
  AppendItemsHandler,
  IGraphQuery,
  IGraphState,
  TemporaryConnectUserHandler,
} from 'Hooks/useGraphContext'

import EventBus from 'Services/EventBus'

export interface ICommunityTags {
  count: number
  rows: MainSchema.Tag[]
}

export interface IOptions {
  users: string[]
  skills: string[]
  needSkills: string[]
  organizations: string[]
  tags: string[]
}

export interface IUseGraphQuery {
  options?: IOptions
  setOptions?: React.Dispatch<React.SetStateAction<IOptions | undefined>>
  setPaths: React.Dispatch<React.SetStateAction<MainSchema.GraphUser[][]>>
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  setGraphState: React.Dispatch<React.SetStateAction<IGraphState>>
  communityTags: ICommunityTags
  handleAppendItems: AppendItemsHandler
  handleTemporaryConnectUser: TemporaryConnectUserHandler
}

export interface IItem {
  id: string
  label: string
  type?: NodeKind
  // TODO: refactor this to be more specific
  value?: any
}

// Only queries for loading items in the background, or loading users, skills, tags, organizations, paths, etc. into the graph
export default function useGraphQuery({
  options,
  setOptions,
  setPaths,
  setIsLoading,
  setGraphState,
  communityTags,
  handleAppendItems,
  handleTemporaryConnectUser,
}: IUseGraphQuery): IGraphQuery {
  const { community } = useCommunityContext()
  const { me } = useAppContext()
  const client = useApolloClient()

  const communityId = community?.id

  const handleExpandUser = useCallback(
    async ({
      userId,
      connectedUserIds,
    }: {
      userId: string
      connectedUserIds: string[]
    }) => {
      if (!communityId) {
        return
      }

      setIsLoading(true)

      const userIds = [userId, ...connectedUserIds]

      const result = await client.query<
        Pick<MainSchema.Query, 'communitySearch'>,
        MainSchema.QueryCommunitySearchArgs
      >({
        query: communitySearchUsersQuery,
        fetchPolicy: 'network-only',
        variables: {
          limit: userIds.length,
          communityId,
          users: userIds,
        },
      })

      setIsLoading(false)

      const users = result?.data?.communitySearch?.users || []
      const expandedUser = users.find(user => user.id === userId)
      const organizations = [
        ...(expandedUser?.workHistory?.map(
          workHistory => workHistory.organization,
        ) ?? []),
        ...(expandedUser?.educationHistory?.map(
          educationHistory => educationHistory.organization,
        ) ?? []),
      ] as MainSchema.Organization[]

      handleAppendItems({
        users,
        organizations,
      })
    },
    [client, communityId, setIsLoading, handleAppendItems],
  )

  const handleSearch = useCallback(
    async ({
      limit = 0,
      users = [],
      skills = [],
      tags = [],
      organizations = [],
    }: {
      limit?: number
      users?: string[]
      skills?: string[]
      tags?: string[]
      organizations?: string[]
    }) => {
      if (!communityId) {
        return
      }

      setIsLoading(true)

      const communityUsersResult = await client.query<
        Pick<MainSchema.Query, 'listCommunityUsers'>,
        MainSchema.QueryListCommunityUsersArgs
      >({
        query: listCommunityUsersQuery,
        fetchPolicy: 'network-only',
        variables: {
          limit,
          communityId,
          userIds: users,
          skillIds: skills,
          tagIds: tags,
          organizationIds: organizations,
        },
      })

      const filteredUserIds =
        communityUsersResult?.data?.listCommunityUsers?.communityUsers.map(
          communityUser => communityUser.userId,
        ) || []

      if (filteredUserIds.length > 0) {
        const result = await client.query<
          Pick<MainSchema.Query, 'communitySearch'>,
          MainSchema.QueryCommunitySearchArgs
        >({
          query: communitySearchUsersQuery,
          fetchPolicy: 'network-only',
          variables: {
            communityId,
            limit: filteredUserIds.length,
            users: filteredUserIds,
          },
        })

        setGraphState(prevState => ({
          ...prevState,
          appendUsers: result?.data?.communitySearch?.users || [],
        }))
      }

      setIsLoading(false)
    },
    [client, communityId, setGraphState, setIsLoading],
  )

  const handleAddOrganization = useCallback(
    async ({
      id,
      isSelected = false,
      isMultiSelect = false,
    }: {
      id: string
      isSelected?: boolean
      isMultiSelect?: boolean
    }) => {
      setIsLoading(true)

      const result = await client.query<
        Pick<MainSchema.Query, 'organization'>,
        MainSchema.QueryOrganizationArgs
      >({
        query: getOrganizationQuery,
        fetchPolicy: 'network-only',
        variables: {
          id,
        },
      })

      const organization = result?.data?.organization

      if (organization) {
        handleAppendItems({
          organizations: [organization],
        })

        // Using timeout here to have an updated state that is handled by handleSearch, this has impact on updateUserSelectionAndActions at useAction.js hook,
        // Without using timeout updateUserSelectionAndActions will have an outdated state because the selection will be updated earlier than handleSearch state
        setTimeout(() => {
          if (isSelected && isMultiSelect)
            setGraphState(prevState => ({
              ...prevState,
              selection: { ...prevState.selection, [organization.id]: true },
            }))
          else if (isSelected)
            setGraphState(prevState => ({
              ...prevState,
              selection: { [organization.id]: true },
            }))
        }, 0)
      }

      setIsLoading(false)
    },
    [setIsLoading, client, handleAppendItems, setGraphState],
  )

  const handleCommunitySearch = useCallback(
    async (item: IItem) => {
      const innerOptions: IOptions = {
        users: [],
        skills: [],
        needSkills: [],
        organizations: [],
        tags: [],
      }

      if (item?.type === SEARCH_TYPES.skill) {
        innerOptions.skills.push(item.id)
        handleAppendItems({ skills: [{ id: item.id, name: item.label }] })
      }

      if (item?.type === SEARCH_TYPES.needSkill) {
        innerOptions.needSkills.push(item.id)
        handleAppendItems({ needSkills: [{ id: item.id, name: item.label }] })
      }

      if (item?.type === SEARCH_TYPES.organization) {
        handleAddOrganization({
          id: item.id,
        })
      }

      if (item?.type === SEARCH_TYPES.user) {
        handleSearch({
          limit: 1,
          users: [item.id],
        })
      }

      if (
        item?.type === SEARCH_TYPES.role ||
        item?.type === SEARCH_TYPES.event ||
        item?.type === SEARCH_TYPES.project ||
        item?.type === SEARCH_TYPES.group ||
        item?.type === SEARCH_TYPES.custom
      ) {
        innerOptions.tags.push(item.id)
        handleAppendItems({
          tags: [{ id: item.id, name: item.label, kind: item.type }],
        })
      }

      setOptions?.({ ...options, ...innerOptions })
    },
    [
      handleAppendItems,
      handleAddOrganization,
      handleSearch,
      options,
      setOptions,
    ],
  )

  const handleSearchByDegrees = useCallback(
    async ({ userId, degrees }: { userId: string; degrees: number }) => {
      if (!communityId) {
        return
      }

      setIsLoading(true)

      const result = await client.query<
        Pick<MainSchema.Query, 'communityUserConnectionsByDegrees'>,
        MainSchema.QueryCommunityUserConnectionsByDegreesArgs
      >({
        query: communityUserConnectionsByDegreesQuery,
        fetchPolicy: 'network-only',
        variables: {
          userId,
          communityId,
          degrees,
        },
      })

      setIsLoading(false)

      setGraphState(prevState => ({
        ...prevState,
        appendUsers: result?.data?.communityUserConnectionsByDegrees || [],
      }))
    },
    [client, communityId, setGraphState, setIsLoading],
  )

  const loadUsers = useCallback(
    async (page: number) => {
      if (!communityId) {
        return undefined
      }

      const result = await client.query<
        Pick<MainSchema.Query, 'communitySearch'>,
        MainSchema.QueryCommunitySearchArgs
      >({
        query: communitySearchUsersQuery,
        fetchPolicy: 'network-only',
        variables: {
          // TODO: the pages are offset by 1
          page: page - 1,
          limit: 200,
          communityId,
        },
      })

      return result
    },
    [client, communityId],
  )

  const handleLoadAllUsers = useCallback(async () => {
    setIsLoading(true)

    const appendUsers: MainSchema.GraphUser[] = []

    const firstPage = 1
    // load the first page to get the total pages that need to be loaded
    const result = await loadUsers(firstPage)
    const totalPages = result?.data.communitySearch.pages
    appendUsers.push(...(result?.data.communitySearch?.users ?? []))

    if (totalPages) {
      // create the queue with a concurrency limit
      const requestQueue = new PQueue({ concurrency: 5 })

      // generate the requests for each page and add them to the queue
      for (
        let currentPage = firstPage + 1;
        currentPage <= totalPages;
        currentPage += 1
      ) {
        requestQueue.add(async () => {
          try {
            const result = await loadUsers(currentPage)

            appendUsers.push(...(result?.data.communitySearch?.users ?? []))
          } catch {
            // TODO: reattempt?
          }
        })
      }

      await requestQueue.onIdle()

      setGraphState(prevState => ({
        ...prevState,
        appendUsers,
        forceLayoutReset: true,
      }))
    }

    setIsLoading(false)
  }, [setIsLoading, loadUsers, setGraphState])

  const handleLoadPath = useCallback(
    async (userId?: string) => {
      if (!userId || !communityId) return []

      const result = await client.query<
        Pick<MainSchema.Query, 'communityPathToUser'>,
        MainSchema.QueryCommunityPathToUserArgs
      >({
        query: communityPathToUserQuery,
        variables: {
          userId,
          communityId,
        },
      })

      const path = result.data?.communityPathToUser

      return isEmpty(path) ? [] : path
    },
    [client, communityId],
  )

  const handleFindPath = useCallback(
    async (userId?: string) => {
      const userPaths = await handleLoadPath(userId)
      if (userPaths?.length > 0) {
        setPaths([userPaths])
      } else {
        setPaths([])
      }
    },
    [handleLoadPath, setPaths],
  )

  const handleAddUserById = useCallback(
    async ({
      userId,
      isSelected = false,
      isMultiSelect = false,
      fromUserId,
    }: {
      userId: string
      isSelected?: boolean
      isMultiSelect?: boolean
      fromUserId?: string
    }) => {
      await handleSearch({ limit: 1, users: [userId] })

      if (fromUserId) {
        // Add fake connection to the graph
        handleTemporaryConnectUser({ fromUserId, toUserId: userId })
      }

      // Using timeout here to have an updated state that is handled by handleSearch, this has impact on updateUserSelectionAndActions at useAction.js hook,
      // Without using timeout updateUserSelectionAndActions will have an outdated state because the selection will be updated earlier than handleSearch state
      setTimeout(() => {
        if (isSelected && isMultiSelect)
          setGraphState(prevState => ({
            ...prevState,
            selection: { ...prevState.selection, [userId]: true },
          }))
        else if (isSelected)
          setGraphState(prevState => ({
            ...prevState,
            selection: { [userId]: true },
          }))
      }, 0)
    },
    [handleSearch, setGraphState, handleTemporaryConnectUser],
  )

  const handleAddUsersById = useCallback(
    async ({
      userIds,
      forceLayoutReset = true,
    }: {
      userIds: string[]
      forceLayoutReset?: boolean
    }) => {
      if (userIds.length === 0) {
        return
      }

      await handleSearch({
        limit: userIds.length,
        users: userIds,
      })
      setGraphState(prevState => ({
        ...prevState,
        forceLayoutReset,
      }))
    },
    [handleSearch, setGraphState],
  )

  const handleMyNetwork = useCallback(
    (degrees: number) => {
      const connectedUsers = keys(me?.graphUser?.connectedUsers || {})

      // No need to perform complex search for 1st degrees, we already know the Ids
      if (connectedUsers.length && degrees === 1) {
        handleSearch({
          limit: connectedUsers.length,
          users: connectedUsers,
        }).then()
      } else if (connectedUsers.length) {
        handleSearchByDegrees({
          userId: me?.id!,
          degrees,
        }).then()
      }
    },
    [me, handleSearch, handleSearchByDegrees],
  )

  const handleLoadAllTags = useCallback(() => {
    handleAppendItems({
      tags: communityTags?.rows,
    })
  }, [handleAppendItems, communityTags?.rows])

  useEventBusSubscribe(EventBus.actions.graph.expandUser, handleExpandUser)
  useEventBusSubscribe(EventBus.actions.graph.findPath, handleFindPath)
  useEventBusSubscribe(EventBus.actions.graph.search, handleSearch)
  useEventBusSubscribe(EventBus.actions.search.community, handleCommunitySearch)
  useEventBusSubscribe(EventBus.actions.graph.addUserById, handleAddUserById)
  useEventBusSubscribe(EventBus.actions.graph.addUsersById, handleAddUsersById)
  useEventBusSubscribe(
    EventBus.actions.graph.addOrganizationById,
    handleAddOrganization,
  )

  return {
    handleSearch,
    handleLoadAllUsers,
    handleLoadAllTags,
    handleMyNetwork,
    handleCommunitySearch,
  }
}

import React, { useCallback, useMemo, useState } from 'react'
import { Form, FormRenderProps } from 'react-final-form'

import { useMutation } from '@apollo/client'
import { format, isBefore, isFuture, parse, parseISO } from 'date-fns'
import updateCommunityUserWorkHistoryMutation from 'GraphQL/Mutations/CommunityUserWorkHistory/updateCommunityUserWorkHistory.graphql'
import createJobTitleMutation from 'GraphQL/Mutations/JobTitle/createJobTitle.graphql'
import createOrganizationMutation from 'GraphQL/Mutations/Organization/createOrganization.graphql'
import validate from 'validate.js'

import JobTitleField, {
  JobTitleOption,
} from 'Components/Blocks/Forms/Fields/JobTitleField/JobTitleField'
import OrganizationField, {
  OrganizationOption,
} from 'Components/Blocks/Forms/Fields/OrganizationField/OrganizationField'
import {
  CheckboxField,
  Column,
  Icon,
  InputField,
  Modal,
  Row,
} from 'Components/UI'
import DateInputField from 'Components/UI/Form/DateInput/DateInputField'

import {
  FACEBOOK_REGEX,
  LINKEDIN_COMPANY_REGEX,
  TWITTER_REGEX,
  URL_REGEX,
} from 'Constants/regex'

import EventBus from 'Services/EventBus'
import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

enum UpdateWorkHistoryFormField {
  Organization = 'organization',
  OrganizationWebsiteUrl = 'organizationWebsiteUrl',
  OrganizationLinkedInUrl = 'organizationLinkedInUrl',
  OrganizationTwitterUrl = 'organizationTwitterUrl',
  OrganizationFacebookUrl = 'organizationFacebookUrl',
  JobTitle = 'jobTitle',
  StartDate = 'startDate',
  EndDate = 'endDate',
  IsPrimary = 'isPrimary',
}

interface UpdateWorkHistoryFormValues {
  [UpdateWorkHistoryFormField.Organization]: OrganizationOption
  [UpdateWorkHistoryFormField.OrganizationWebsiteUrl]: string
  [UpdateWorkHistoryFormField.OrganizationLinkedInUrl]: string
  [UpdateWorkHistoryFormField.OrganizationTwitterUrl]: string
  [UpdateWorkHistoryFormField.OrganizationFacebookUrl]: string
  [UpdateWorkHistoryFormField.JobTitle]: JobTitleOption
  [UpdateWorkHistoryFormField.StartDate]: string
  [UpdateWorkHistoryFormField.EndDate]: string
  [UpdateWorkHistoryFormField.IsPrimary]: boolean
}

export interface EditWorkHistoryModalProps {
  workHistory: MainSchema.CommunityUserWorkHistory
  onClose?: (success: boolean) => void
}

validate.validators.date = (value: string) => {
  const MONTH_DATE_REGEX = /^(0[1-9]|1[0-2])\/[0-9]{4}$/

  if (value && !MONTH_DATE_REGEX.test(value)) {
    return '^Date is invalid'
  }

  return null
}

export default function UpdateWorkHistoryModal({
  workHistory,
  onClose,
}: EditWorkHistoryModalProps) {
  const t = useScopedI18n('components.blocks.modals.updateWorkHistory')
  const [isLoading, setIsLoading] = useState(false)
  const [createOrganization] = useMutation<
    Pick<MainSchema.Mutation, 'createOrganization'>,
    MainSchema.MutationCreateOrganizationArgs
  >(createOrganizationMutation)
  const [createJobTitle] = useMutation<
    Pick<MainSchema.Mutation, 'createJobTitle'>,
    MainSchema.MutationCreateJobTitleArgs
  >(createJobTitleMutation)
  const [updateCommunityUserWorkHistory] = useMutation<
    Pick<MainSchema.Mutation, 'updateCommunityUserWorkHistory'>,
    MainSchema.MutationUpdateCommunityUserWorkHistoryArgs
  >(updateCommunityUserWorkHistoryMutation)

  const updateWorkHistoryFormConstraints = useMemo(() => {
    return {
      [UpdateWorkHistoryFormField.Organization]: {
        type: 'object',
        presence: {
          allowEmpty: false,
          message: `^${t('form.organization.validation.required')}`,
        },
      },
      [UpdateWorkHistoryFormField.OrganizationWebsiteUrl]: {
        type: 'string',
        format: {
          pattern: URL_REGEX,
          message: `^${t('form.organizationWebsiteUrl.validation.invalid')}`,
        },
      },
      [UpdateWorkHistoryFormField.OrganizationLinkedInUrl]: {
        type: 'string',
        format: {
          pattern: LINKEDIN_COMPANY_REGEX,
          message: `^${t('form.organizationLinkedInUrl.validation.invalid')}`,
        },
      },
      [UpdateWorkHistoryFormField.OrganizationTwitterUrl]: {
        type: 'string',
        format: {
          pattern: TWITTER_REGEX,
          message: `^${t('form.organizationTwitterUrl.validation.invalid')}`,
        },
      },
      [UpdateWorkHistoryFormField.OrganizationFacebookUrl]: {
        type: 'string',
        format: {
          pattern: FACEBOOK_REGEX,
          message: `^${t('form.organizationFacebookUrl.validation.invalid')}`,
        },
      },
      [UpdateWorkHistoryFormField.JobTitle]: {
        type: 'object',
        presence: {
          allowEmpty: false,
          message: `^${t('form.jobTitle.validation.required')}`,
        },
      },
      [UpdateWorkHistoryFormField.StartDate]: {
        type: 'string',
        presence: {
          allowEmpty: false,
          message: `^${t('form.startDate.validation.required')}`,
        },
        date: {
          message: `^${t('form.startDate.validation.invalid')}`,
        },
      },
    }
  }, [t])

  const initialValues = useMemo(() => {
    return {
      [UpdateWorkHistoryFormField.Organization]: workHistory.organization
        ? ({
            label: workHistory.organization.name,
            value: workHistory.organization.id,
            isNew: false,
          } as OrganizationOption)
        : undefined,
      [UpdateWorkHistoryFormField.JobTitle]: workHistory.jobTitle
        ? ({
            label: workHistory.jobTitle.name,
            value: workHistory.jobTitle.id,
            isNew: false,
          } as JobTitleOption)
        : undefined,
      [UpdateWorkHistoryFormField.StartDate]: workHistory.startDate
        ? format(parseISO(workHistory.startDate), 'MM/yyyy')
        : undefined,
      [UpdateWorkHistoryFormField.EndDate]: workHistory.endDate
        ? format(parseISO(workHistory.endDate), 'MM/yyyy')
        : undefined,
      [UpdateWorkHistoryFormField.IsPrimary]: workHistory.isPrimary,
    }
  }, [workHistory])

  const submit = useCallback(
    async (values: UpdateWorkHistoryFormValues) => {
      setIsLoading(true)

      try {
        const organization = values[UpdateWorkHistoryFormField.Organization]
        const jobTitle = values[UpdateWorkHistoryFormField.JobTitle]
        const isPrimary = values[UpdateWorkHistoryFormField.IsPrimary]
        const startDate = parse(
          values[UpdateWorkHistoryFormField.StartDate],
          'MM/yyyy',
          new Date(),
        )
        const endDate =
          !isPrimary && values[UpdateWorkHistoryFormField.EndDate]
            ? parse(
                values[UpdateWorkHistoryFormField.EndDate],
                'MM/yyyy',
                new Date(),
              )
            : null
        let organizationId = !organization.isNew
          ? organization?.value
          : undefined
        let jobTitleId = !jobTitle.isNew ? jobTitle?.value : undefined

        const isEndDateBeforeStartDate = endDate && isBefore(endDate, startDate)

        if (isEndDateBeforeStartDate) {
          throw new Error(t('form.startDate.validation.beforeEndDate'))
        }

        if (isFuture(startDate)) {
          throw new Error(t('form.startDate.validation.future'))
        }

        if (organization && organization.isNew) {
          const organizationResult = await createOrganization({
            variables: {
              input: {
                name: organization.value,
                websiteUrl:
                  values[UpdateWorkHistoryFormField.OrganizationWebsiteUrl],
                linkedInUrl:
                  values[UpdateWorkHistoryFormField.OrganizationLinkedInUrl],
                twitterUrl:
                  values[UpdateWorkHistoryFormField.OrganizationTwitterUrl],
                facebookUrl:
                  values[UpdateWorkHistoryFormField.OrganizationFacebookUrl],
              },
            },
          })

          if (organizationResult.data?.createOrganization.id) {
            organizationId = organizationResult.data.createOrganization.id
          }
        }

        if (jobTitle && jobTitle.isNew) {
          const jobTitleResult = await createJobTitle({
            variables: {
              input: {
                name: jobTitle.value,
              },
            },
          })

          if (jobTitleResult.data?.createJobTitle.id) {
            jobTitleId = jobTitleResult.data.createJobTitle.id
          }
        }

        await updateCommunityUserWorkHistory({
          variables: {
            input: {
              id: workHistory.id,
              organizationId,
              jobTitleId,
              startDate: startDate ? format(startDate, 'yyyy-MM-dd') : null,
              endDate: endDate ? format(endDate, 'yyyy-MM-dd') : null,
              isPrimary,
            },
          },
        })

        EventBus.trigger(EventBus.actions.profile.reload)

        // TODO: update graph

        toast.success({
          title: t('toast.title'),
          text: t('toast.success'),
        })

        onClose?.(true)
      } catch (error) {
        let message = _('error.generic')

        if (error instanceof Error) {
          message = error.message
        }

        toast.error({
          title: t('toast.title'),
          text: message,
        })
      } finally {
        setIsLoading(false)
      }
    },
    [
      onClose,
      t,
      createOrganization,
      createJobTitle,
      updateCommunityUserWorkHistory,
      workHistory,
    ],
  )

  const renderForm = useCallback(
    ({
      handleSubmit,
      valid,
      values,
    }: FormRenderProps<UpdateWorkHistoryFormValues>) => {
      const isCreatingOrganization =
        values[UpdateWorkHistoryFormField.Organization]?.isNew

      return (
        <Modal
          cancelText={t('form.cancel')}
          confirmDisabled={isLoading || !valid}
          confirmText={t('form.submit')}
          isOpen
          title={t('title')}
          width="456px"
          onClose={() => onClose?.(false)}
          onConfirm={handleSubmit}
        >
          <Column gap={3}>
            <OrganizationField
              isRequired
              label={t('form.organization.label')}
              name={UpdateWorkHistoryFormField.Organization}
              placeholder={t('form.organization.placeholder')}
            />

            {isCreatingOrganization && (
              <>
                <InputField
                  checkErrorIfDirty
                  clearable
                  label={t('form.organizationWebsiteUrl.label')}
                  name={UpdateWorkHistoryFormField.OrganizationWebsiteUrl}
                />

                <InputField
                  checkErrorIfDirty
                  clearable
                  label={
                    <Row alignItems="center" gap={2}>
                      {t('form.organizationLinkedInUrl.label')}
                      <Icon.LinkedIn size={10} />
                    </Row>
                  }
                  name={UpdateWorkHistoryFormField.OrganizationLinkedInUrl}
                />

                <InputField
                  checkErrorIfDirty
                  clearable
                  label={
                    <Row alignItems="center" gap={2}>
                      {t('form.organizationTwitterUrl.label')}
                      <Icon.Twitter size={10} />
                    </Row>
                  }
                  name={UpdateWorkHistoryFormField.OrganizationTwitterUrl}
                />

                <InputField
                  checkErrorIfDirty
                  clearable
                  label={
                    <Row alignItems="center" gap={2}>
                      {t('form.organizationFacebookUrl.label')}
                      <Icon.Facebook size={10} />
                    </Row>
                  }
                  name={UpdateWorkHistoryFormField.OrganizationFacebookUrl}
                />
              </>
            )}

            <JobTitleField
              isRequired
              label={t('form.jobTitle.label')}
              name={UpdateWorkHistoryFormField.JobTitle}
              placeholder={t('form.jobTitle.placeholder')}
            />

            <Row gap={6} spaceBetween>
              <Column flex={1} gap={2}>
                <DateInputField
                  checkErrorIfDirty
                  clearable
                  label={t('form.startDate.label')}
                  name={UpdateWorkHistoryFormField.StartDate}
                  required
                />
              </Column>

              <Column flex={1} gap={2}>
                <DateInputField
                  checkErrorIfDirty
                  clearable
                  disabled={values[UpdateWorkHistoryFormField.IsPrimary]}
                  label={t('form.endDate.label')}
                  name={UpdateWorkHistoryFormField.EndDate}
                />

                <CheckboxField
                  label={t('form.current.label')}
                  name={UpdateWorkHistoryFormField.IsPrimary}
                />
              </Column>
            </Row>
          </Column>
        </Modal>
      )
    },
    [isLoading, onClose, t],
  )

  return (
    <Form<UpdateWorkHistoryFormValues>
      initialValues={initialValues}
      render={renderForm}
      validate={values => validate(values, updateWorkHistoryFormConstraints)}
      onSubmit={submit}
    />
  )
}

import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useMutation } from '@apollo/client'

import { Loader } from 'Components/UI'

import { OAuthProvider, OAuthResponseKind } from 'Constants/mainGraphQL'

import * as ROUTES from 'Router/routes'

import AuthService from 'Services/Auth'
import toast from 'Services/Toast'

import ITokens from '../IToken'
import connectOAuthMutation from '../Mutations/connectOAuth.graphql'

interface IOAuthArgs {
  provider: OAuthProvider
  type: OAuthCallerKind
  code?: string
}

enum OAuthCallerKind {
  SignIn = 'signIn',
  SignUp = 'signUp',
  Connect = 'connect',
  Onboarding = 'onboarding',
}

const connectTitle = 'Connection Error'
const generalErrorMessage = 'Something went wrong, please try again later'

export default function OAuthConnectView() {
  const args: IOAuthArgs = React.useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const encodedState = urlParams?.get('state')
    const decodedState = JSON.parse(decodeURIComponent(encodedState || '{}'))
    decodedState.code = urlParams?.get('code')
    return decodedState
  }, [])

  const navigate = useNavigate()
  const [connectOAuth] = useMutation(connectOAuthMutation)

  // On error display toast and redirect to calling page
  const handleError = React.useCallback(
    (title: string, message: string) => {
      const navigationRoute =
        args?.type === OAuthCallerKind.Connect
          ? ROUTES.PROFILE_ROOT
          : ROUTES.AUTH_ROOT
      navigate(navigationRoute)
      toast.error({ title, text: message })
    },
    [navigate, args?.type],
  )

  // On successful authentication, store tokens and redirect to calling page
  const handleAuthSuccess = React.useCallback(
    async (tokens: ITokens) => {
      await AuthService.handleAuth(tokens)

      switch (args?.type) {
        case OAuthCallerKind.SignIn:
        case OAuthCallerKind.SignUp:
          navigate(ROUTES.COMMUNITY_PANEL)
          break
        case OAuthCallerKind.Connect:
          navigate(ROUTES.PROFILE_ROOT)
          break
        case OAuthCallerKind.Onboarding:
          navigate(ROUTES.ONBOARDING_CONNECT)
          break
        default:
          navigate(ROUTES.COMMUNITY_PANEL)
          break
      }
    },
    [navigate, args],
  )

  const handleOnPageLoad = React.useCallback(
    async (provider: string, code: string) => {
      // Exchange code for tokens
      const { data } = await connectOAuth({
        variables: {
          provider: provider as OAuthProvider,
          code,
        },
      })

      switch (data?.connectOAuth?.kind) {
        case OAuthResponseKind.Success:
        case OAuthResponseKind.Exists:
        case OAuthResponseKind.Created:
        case OAuthResponseKind.Deleted:
          handleAuthSuccess({
            accessToken: data?.connectOAuth?.accessToken,
            refreshToken: data?.connectOAuth?.refreshToken,
          })
          break
        case OAuthResponseKind.RequiresConsent:
          // If consent is required, redirect to consent page using updated consentUrl and state
          window.location.href = `${data?.connectOAuth?.consentUrl}&state=${encodeURIComponent(JSON.stringify(args))}`
          break
        default:
          break
      }
    },
    [connectOAuth, args, handleAuthSuccess],
  )

  // On Page load, process the OAuth callback params
  React.useEffect(() => {
    const handleOAuthCallback = async () => {
      const code = args?.code
      const type = args?.type
      const provider = args?.provider

      if (!code || !provider || !type) {
        handleError(connectTitle, generalErrorMessage)
        return
      }

      try {
        switch (type) {
          case OAuthCallerKind.SignIn:
          case OAuthCallerKind.SignUp:
          case OAuthCallerKind.Connect:
          case OAuthCallerKind.Onboarding:
            await handleOnPageLoad(provider, code)
            break
          default:
            handleError(connectTitle, generalErrorMessage)
        }
      } catch (error) {
        let message = generalErrorMessage

        if (error instanceof Error) {
          message = error.message
        }

        handleError(connectTitle, message)
      }
    }

    handleOAuthCallback()
    // run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Loader absolute />
}

import React, { useCallback } from 'react'

import { useMutation } from '@apollo/client'
import deleteProposalMutation from 'GraphQL/Mutations/Proposal/deleteProposal.graphql'
import { deleteProposalForUserUpdater } from 'GraphQL/Updaters/ProposalForUser'

import uniqBy from 'lodash/uniqBy'

import { Dialog } from 'Components/Blocks/Modals'
import { ContactProposal } from 'Components/Blocks/Proposal'
import { Column } from 'Components/UI'
import { ConditionalLink, Text } from 'Components/UI/_v2'

import { PROPOSAL_KIND } from 'Constants/ids'
import { ProposalKind } from 'Constants/mainGraphQL'

import { useAppContext, useCommunityContext, useEntityModal } from 'Hooks'

import _ from 'Services/I18n'
import toast from 'Services/Toast'

import colors from 'Theme/_v2/colors'

import { Button, CloseIcon, Container } from './styles'

import Card from '../Card'
import ShowMore from '../ShowMore'

const PRIVATE = 'Private'

export interface IProposal {
  id?: string
  kind: ProposalKind
  name?: string
}

export interface IContactEmail {
  id?: string
  entityId?: string
  email?: string
  isProposal?: boolean
  isMyProposal: boolean
}

export interface IContactPhoneNumber {
  id?: string
  entityId?: string
  phoneNumber?: string
  isProposal?: boolean
  isMyProposal: boolean
}

export interface IContactBoxProps {
  sharedUserId?: string
  showCreateProposals?: boolean
  user: MainSchema.GraphUser | MainSchema.CommunityUser
}

function ContactBox({
  showCreateProposals = true,
  user,
  sharedUserId,
}: IContactBoxProps) {
  const { community } = useCommunityContext()
  const { me } = useAppContext()
  const [deleteModal, deleteActions] = useEntityModal<IProposal>()

  const [deleteProposal] = useMutation<
    Pick<MainSchema.Mutation, 'deleteProposal'>,
    MainSchema.MutationDeleteProposalArgs
  >(deleteProposalMutation)

  const handleOpenDeleteModal = deleteActions.openModal
  const handleDeleteModalClose = deleteActions.closeModal

  let emails: IContactEmail[] = []
  let phoneNumbers: IContactPhoneNumber[] = []

  if ('proposals' in user) {
    const emailProposals =
      user.proposals?.filter(
        proposal => proposal.kind === PROPOSAL_KIND.EMAIL,
      ) ?? []
    const phoneNumberProposals =
      user.proposals?.filter(
        proposal => proposal.kind === PROPOSAL_KIND.PHONE,
      ) ?? []

    emails = uniqBy(
      emailProposals.map(record => ({
        id: record.id,
        entityId: record.entityId,
        email: record.value,
        isProposal: !!record.kind,
        isMyProposal: record.creatorId === me?.id,
      })),
      'email',
    )

    phoneNumbers = uniqBy(
      phoneNumberProposals.map(record => ({
        id: record.id,
        entityId: record.entityId,
        phoneNumber: record.value,
        isProposal: !!record.kind,
        isMyProposal: record.creatorId === me?.id,
      })),
      'phoneNumber',
    )
  } else if ('emails' in user) {
    emails = uniqBy(
      user.emails?.map(record => ({
        id: record.id,
        entityId: record.entityId,
        email: record.value,
        isProposal: record.isProposal,
        isMyProposal: record.creatorId === me?.id,
      })),
      'email',
    )

    phoneNumbers = uniqBy(
      user.phoneNumbers?.map(record => ({
        id: record.id,
        entityId: record.entityId,
        phoneNumber: record.value,
        isProposal: record.isProposal,
        isMyProposal: record.creatorId === me?.id,
      })),
      'phoneNumber',
    )
  }

  const handleDelete = useCallback(
    async (success: boolean) => {
      if (!success || !deleteModal.entity?.id) return

      try {
        await deleteProposal({
          variables: { id: deleteModal.entity.id },
          update: deleteProposalForUserUpdater({
            userId: user.userId!,
            communityId: community?.id,
            proposalId: deleteModal.entity.id,
            sharedUserId,
          }),
        })

        toast.success({
          title: 'Delete proposal',
          text: `Proposal successfully deleted`,
        })
      } catch (error) {
        let message = _('error.generic')

        if (error instanceof Error) {
          message = _(`error.${error.message || 'generic'}`)
        }

        toast.error({
          title: 'Delete proposal',
          text: message,
        })
      }
    },
    [community, deleteModal, deleteProposal, sharedUserId, user],
  )

  return (
    <>
      <Card>
        <ContactProposal
          sharedUserId={sharedUserId}
          showCreateProposals={showCreateProposals}
          userId={user.userId!}
        />

        <Column gap={1} pl={5}>
          <ShowMore initialShown={3}>
            {emails.map(item => (
              <Container key={item.id}>
                <ConditionalLink
                  condition={item.email !== PRIVATE}
                  href={`mailto:${item.email}`}
                  isExternal
                >
                  <Text
                    color={colors.main.primary}
                    ellipsis
                    fontSize={'12px'}
                    fontWeight={500}
                    lineHeight={'16px'}
                  >
                    {item.email}
                  </Text>
                </ConditionalLink>
                {item.isProposal && item.isMyProposal && (
                  <Button
                    onClick={event => {
                      event.preventDefault()
                      event.stopPropagation()

                      handleOpenDeleteModal({
                        id: item.id,
                        kind: ProposalKind.Email,
                        name: item.email,
                      })
                    }}
                  >
                    <CloseIcon />
                  </Button>
                )}
              </Container>
            ))}

            {phoneNumbers.map(item => (
              <Container key={item.id}>
                <ConditionalLink
                  condition={item.phoneNumber !== PRIVATE}
                  href={`tel:${item.phoneNumber}`}
                  isExternal
                >
                  <Text
                    color={colors.main.primary}
                    ellipsis
                    fontSize={'12px'}
                    fontWeight={500}
                    lineHeight={'16px'}
                  >
                    {item.phoneNumber}
                  </Text>
                </ConditionalLink>
                {item.isProposal && item.isMyProposal && (
                  <Button
                    onClick={event => {
                      event.preventDefault()
                      event.stopPropagation()

                      handleOpenDeleteModal({
                        id: item.id,
                        kind: ProposalKind.Phone,
                        name: item.phoneNumber,
                      })
                    }}
                  >
                    <CloseIcon />
                  </Button>
                )}
              </Container>
            ))}
          </ShowMore>
        </Column>
      </Card>

      {deleteModal.entity && (
        <Dialog
          content={`Are you sure you want to delete your ${deleteModal.entity.kind} proposal: ${deleteModal.entity.name}?`}
          isOpen={deleteModal.isOpen}
          title="Delete proposal?"
          onClose={handleDeleteModalClose}
          onFinish={handleDelete}
        />
      )}
    </>
  )
}

export default ContactBox

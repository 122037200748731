import React from 'react'
import { FieldArray } from 'react-final-form-arrays'

import { IconPlus } from '@tabler/icons-react'

import { Link, Text } from 'Components/UI/_v2'
import { Column, Row } from 'Components/UI/Flex'

import InputField from '../Input/InputField'

export interface InputArrayFieldItem {
  name: string
}

export interface InputArrayFieldProps {
  name: string
  label: React.ReactNode
  removeLabel: string
  addLabel: React.ReactNode
}

export const EMPTY_VALUE: InputArrayFieldItem = {
  name: '',
}

export default function InputArrayField(props: InputArrayFieldProps) {
  return (
    <FieldArray<InputArrayFieldItem> name={props.name}>
      {({ fields }) => {
        return (
          <Column gap={2}>
            {fields.map((name, index) => {
              return (
                <InputField
                  checkErrorIfDirty
                  clearTitle={props.removeLabel}
                  clearable
                  key={name}
                  label={props.label}
                  name={`${name}.name`}
                  onClear={() => {
                    if (fields.value.length <= 1) {
                      fields.update(index, EMPTY_VALUE)
                    } else {
                      fields.remove(index)
                    }
                  }}
                />
              )
            })}

            <Link onClick={() => fields.push(EMPTY_VALUE)}>
              <Row center gap={1}>
                <IconPlus size={8} stroke={4.5} />
                <Text fontSize={'14px'} fontWeight={600} lineHeight={'16px'}>
                  {props.addLabel}
                </Text>
              </Row>
            </Link>
          </Column>
        )
      }}
    </FieldArray>
  )
}

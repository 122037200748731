import React, { useState } from 'react'

import { useMutation } from '@apollo/client'
import deleteCommunityUserEducationHistoryMutation from 'GraphQL/Mutations/CommunityUserEducationHistory/deleteCommunityUserEducationHistory.graphql'
import { formatEducation } from 'Utils/User'

import { Column, Modal } from 'Components/UI'
import { Text } from 'Components/UI/_v2'

import EventBus from 'Services/EventBus'
import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import colors from 'Theme/_v2/colors'

export interface DeleteEducationHistoryModalProps {
  educationHistory: MainSchema.CommunityUserEducationHistory
  onClose?: (success: boolean) => void
}

function DeleteEducationHistoryModal({
  educationHistory,
  onClose,
}: DeleteEducationHistoryModalProps) {
  const t = useScopedI18n('components.blocks.modals.deleteEducationHistory')
  const [isLoading, setIsLoading] = useState(false)
  const [deleteCommunityUserEducationHistory] = useMutation<
    Pick<MainSchema.Mutation, 'deleteCommunityUserEducationHistory'>,
    MainSchema.MutationDeleteCommunityUserEducationHistoryArgs
  >(deleteCommunityUserEducationHistoryMutation)

  const handleClose = () => {
    onClose?.(false)
  }

  const handleConfirm = async () => {
    setIsLoading(true)

    try {
      await deleteCommunityUserEducationHistory({
        variables: {
          input: {
            id: educationHistory.id,
          },
        },
      })

      EventBus.trigger(EventBus.actions.profile.reload)

      toast.success({
        title: t('toast.title'),
        text: t('toast.success'),
      })

      onClose?.(true)
    } catch (error) {
      let message = _('error.generic')

      if (error instanceof Error) {
        message = _(`error.${error.message || 'generic'}`)
      }

      toast.error({
        title: t('toast.title'),
        text: message,
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      cancelText={t('form.cancel')}
      confirmDisabled={isLoading}
      confirmText={t('form.submit')}
      isOpen
      title={t('title')}
      onClose={handleClose}
      onConfirm={handleConfirm}
    >
      <Column gap={4} width={['100%', '100%', '600px']}>
        <Text
          color={colors.text.primary}
          fontSize={'14px'}
          fontWeight={400}
          lineHeight={'19px'}
        >
          {t('content', {
            educationHistory: formatEducation(educationHistory, ', '),
          })}
        </Text>
      </Column>
    </Modal>
  )
}

export default DeleteEducationHistoryModal

import React from 'react'
import { Navigate, RouteObject } from 'react-router-dom'

import { RootLayout } from 'Components/Layout'
import RestrictedLayout from 'Components/Layout/Community/RestrictedLayout'

import { USER_ROLE } from 'Constants/ids'

import SignOut from 'Pages/Auth/SignOut'
import Connect from 'Pages/Onboarding/Connect'
import Welcome from 'Pages/Onboarding/Welcome'
import Public from 'Pages/Public'

import { ADMIN_ROUTES } from './admin'
import { AUTH_ROUTES } from './auth'
import { getCommunityRoutes, RESTRICTED_COMMUNITY_ROUTES } from './community'
import { PROFILE_ROUTES } from './profile'
import * as ROUTES from './routes'

import UIKit from '../Pages/UIKit'

const AdminRedirect = () => <Navigate to={ROUTES.ADMIN_ROOT} />
const AppRedirect = () => <Navigate to={ROUTES.COMMUNITY} />
const AuthRedirect = () => <Navigate to={ROUTES.AUTH_SIGN_IN} />

const getRoutes = (
  user?: MainSchema.User | undefined,
  restricted: boolean = false,
): RouteObject[] => {
  const allRoutes: RouteObject[] = [
    {
      element: <Public.Root />,
      path: ROUTES.ROOT,
    },
    {
      element: <Public.Redirector />,
      path: ROUTES.REDIRECTOR,
    },
  ]

  if (user) {
    switch (user.role) {
      case USER_ROLE.SUPER_ADMIN:
        allRoutes.push(ADMIN_ROUTES)

        allRoutes.push({
          path: ROUTES.AUTH_SIGN_IN,
          element: <AdminRedirect />,
        })

        break

      case USER_ROLE.USER:
        allRoutes.push(
          restricted ? RESTRICTED_COMMUNITY_ROUTES : getCommunityRoutes(),
        )

        allRoutes.push({
          element: <Public.AcceptCommunityInvite />,
          path: ROUTES.ACCEPT_COMMUNITY_INVITE,
        })

        allRoutes.push({
          element: <Public.VerifyEmail />,
          path: ROUTES.VERIFY_EMAIL,
        })

        allRoutes.push({
          path: ROUTES.AUTH_SIGN_IN,
          element: <AppRedirect />,
        })

        allRoutes.push({
          element: <RestrictedLayout />,
          path: ROUTES.ROOT,
          children: [
            {
              element: <Welcome />,
              path: ROUTES.ONBOARDING_WELCOME,
            },
            {
              element: <Connect />,
              path: ROUTES.ONBOARDING_CONNECT,
            },
          ],
        })

        allRoutes.push(PROFILE_ROUTES)

        break

      default:
        break
    }
  } else {
    allRoutes.push(AUTH_ROUTES)
  }

  allRoutes.push({
    element: <SignOut />,
    path: ROUTES.SIGN_OUT,
  })

  allRoutes.push({
    element: <UIKit />,
    path: ROUTES.UI_KIT,
  })

  allRoutes.push({
    element: <Public.OAuthConnectView />,
    path: ROUTES.OAUTH_CALLBACK,
  })

  allRoutes.push({
    path: '*',
    element: <AuthRedirect />,
  })

  return [
    {
      element: <RootLayout />,
      children: allRoutes,
    },
  ]
}

export default getRoutes

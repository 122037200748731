import React from 'react'
import { Field } from 'react-final-form'

import Utils from 'Utils'

import DateInput from './DateInput'

import { IInputField } from '../Input/InputField'

export interface DateInputFieldProps extends IInputField {}

export default function DateInputField({
  caption,
  checkErrorIfDirty,
  displayError = true,
  name,
  required = false,
  validate,
  ...rest
}: DateInputFieldProps) {
  return (
    <Field name={name} validate={validate}>
      {({ input: { value, onChange, onBlur, onFocus }, meta }) => {
        const { hasError, error } = Utils.Form.hasError(meta, checkErrorIfDirty)

        return (
          <DateInput
            {...rest}
            caption={(displayError && error) || caption}
            error={hasError}
            required={required}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            onClear={() => onChange(null)}
            onFocus={onFocus}
          />
        )
      }}
    </Field>
  )
}

import React, { MouseEventHandler, PropsWithChildren } from 'react'

import Utils from 'Utils'

import { Avatar, Column, Row, Tag, Text } from 'Components/UI'

import { DRAG_DROP } from 'Constants/ids'
import { TAG_COLOR_KIND } from 'Constants/tags'

import { Wrapper } from './styles'

export interface ISimpleUserCardUser {
  id: MainSchema.CommunityUser['id']
  firstName: MainSchema.CommunityUser['firstName']
  lastName: MainSchema.CommunityUser['lastName']
  photoUrl: MainSchema.CommunityUser['photoUrl']
  jobTitle?: MainSchema.JobTitle['name']
  organization?: MainSchema.Organization['name']
}

export interface ISimpleUserCard extends PropsWithChildren {
  id?: string
  // TODO: Replace with MainSchema.CommunityUser once MainSchema.GraphUser has been removed
  user: ISimpleUserCardUser
  selected?: boolean
  onClick?: MouseEventHandler<HTMLDivElement>
}

function SimpleUserCard({
  children,
  id,
  selected,
  user,
  onClick,
}: ISimpleUserCard) {
  return (
    <Wrapper
      data={user.id}
      id={id}
      selected={selected}
      type={DRAG_DROP.USER}
      onClick={onClick}
    >
      <Row center gap={3}>
        <Avatar src={user.photoUrl} />

        <Column>
          <Text breakWord header header3>
            {Utils.User.getFullName(user)}
          </Text>

          {user.jobTitle && (
            <Text body bodySmall>
              {user.jobTitle}
            </Text>
          )}
        </Column>
      </Row>

      <Row center>
        {!!user.organization && (
          <Tag
            colorKind={TAG_COLOR_KIND.COMPANY}
            maxWidth="80px"
            small
            text={user.organization}
          />
        )}

        {children}
      </Row>
    </Wrapper>
  )
}

export default SimpleUserCard

import React, { useCallback, useMemo, useState } from 'react'
import { Form, FormRenderProps } from 'react-final-form'

import { useMutation } from '@apollo/client'
import { format, isBefore, isFuture, parse } from 'date-fns'
import arrayMutators from 'final-form-arrays'
import createCommunityUserEducationHistoryMutation from 'GraphQL/Mutations/CommunityUserEducationHistory/createCommunityUserEducationHistory.graphql'
import createOrganizationMutation from 'GraphQL/Mutations/Organization/createOrganization.graphql'
import validate from 'validate.js'

import OrganizationField, {
  OrganizationOption,
} from 'Components/Blocks/Forms/Fields/OrganizationField/OrganizationField'
import { Column, Icon, InputField, Modal, Row } from 'Components/UI'
import DateInputField from 'Components/UI/Form/DateInput/DateInputField'
import InputArrayField, {
  EMPTY_VALUE,
  InputArrayFieldItem,
} from 'Components/UI/Form/InputArray/InputArrayField'

import {
  FACEBOOK_REGEX,
  LINKEDIN_COMPANY_REGEX,
  TWITTER_REGEX,
  URL_REGEX,
} from 'Constants/regex'

import EventBus from 'Services/EventBus'
import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

enum CreateEducationHistoryFormField {
  Organization = 'organization',
  OrganizationWebsiteUrl = 'organizationWebsiteUrl',
  OrganizationLinkedInUrl = 'organizationLinkedInUrl',
  OrganizationTwitterUrl = 'organizationTwitterUrl',
  OrganizationFacebookUrl = 'organizationFacebookUrl',
  StartDate = 'startDate',
  EndDate = 'endDate',
  Degrees = 'degrees',
  Majors = 'majors',
  Minors = 'minors',
}

interface CreateEducationHistoryFormValues {
  [CreateEducationHistoryFormField.Organization]: OrganizationOption
  [CreateEducationHistoryFormField.OrganizationWebsiteUrl]: string
  [CreateEducationHistoryFormField.OrganizationLinkedInUrl]: string
  [CreateEducationHistoryFormField.OrganizationTwitterUrl]: string
  [CreateEducationHistoryFormField.OrganizationFacebookUrl]: string
  [CreateEducationHistoryFormField.StartDate]: string
  [CreateEducationHistoryFormField.EndDate]: string
  [CreateEducationHistoryFormField.Degrees]: InputArrayFieldItem[]
  [CreateEducationHistoryFormField.Majors]: InputArrayFieldItem[]
  [CreateEducationHistoryFormField.Minors]: InputArrayFieldItem[]
}

export interface CreateEducationHistoryModalProps {
  user: MainSchema.GraphUser | MainSchema.CommunityUser
  onClose?: (success: boolean) => void
}

validate.validators.date = (value: string) => {
  const MONTH_DATE_REGEX = /^(0[1-9]|1[0-2])\/[0-9]{4}$/

  if (value && !MONTH_DATE_REGEX.test(value)) {
    return '^Date is invalid'
  }

  return null
}

function CreateEducationHistoryModal({
  user,
  onClose,
}: CreateEducationHistoryModalProps) {
  const t = useScopedI18n('components.blocks.modals.createEducationHistory')
  const [isLoading, setIsLoading] = useState(false)
  const [createOrganization] = useMutation<
    Pick<MainSchema.Mutation, 'createOrganization'>,
    MainSchema.MutationCreateOrganizationArgs
  >(createOrganizationMutation)
  const [createCommunityUserEducationHistory] = useMutation<
    Pick<MainSchema.Mutation, 'createCommunityUserEducationHistory'>,
    MainSchema.MutationCreateCommunityUserEducationHistoryArgs
  >(createCommunityUserEducationHistoryMutation)

  const createEducationHistoryFormConstraints = useMemo(() => {
    return {
      [CreateEducationHistoryFormField.Organization]: {
        type: 'object',
        presence: {
          allowEmpty: false,
          message: `^${t('form.organization.validation.required')}`,
        },
      },
      [CreateEducationHistoryFormField.OrganizationWebsiteUrl]: {
        type: 'string',
        format: {
          pattern: URL_REGEX,
          message: `^${t('form.organizationWebsiteUrl.validation.invalid')}`,
        },
      },
      [CreateEducationHistoryFormField.OrganizationLinkedInUrl]: {
        type: 'string',
        format: {
          pattern: LINKEDIN_COMPANY_REGEX,
          message: `^${t('form.organizationLinkedInUrl.validation.invalid')}`,
        },
      },
      [CreateEducationHistoryFormField.OrganizationTwitterUrl]: {
        type: 'string',
        format: {
          pattern: TWITTER_REGEX,
          message: `^${t('form.organizationTwitterUrl.validation.invalid')}`,
        },
      },
      [CreateEducationHistoryFormField.OrganizationFacebookUrl]: {
        type: 'string',
        format: {
          pattern: FACEBOOK_REGEX,
          message: `^${t('form.organizationFacebookUrl.validation.invalid')}`,
        },
      },
      [CreateEducationHistoryFormField.StartDate]: {
        type: 'string',
        presence: {
          allowEmpty: false,
          message: `^${t('form.startDate.validation.required')}`,
        },
        date: {
          message: `^${t('form.startDate.validation.invalid')}`,
        },
      },
    }
  }, [t])

  const initialValues = useMemo(() => {
    return {
      [CreateEducationHistoryFormField.Degrees]: [EMPTY_VALUE],
      [CreateEducationHistoryFormField.Majors]: [EMPTY_VALUE],
      [CreateEducationHistoryFormField.Minors]: [EMPTY_VALUE],
    }
  }, [])

  const submit = useCallback(
    async (values: CreateEducationHistoryFormValues) => {
      if (!user.communityUserId) {
        return
      }

      setIsLoading(true)

      try {
        const organization =
          values[CreateEducationHistoryFormField.Organization]
        const organizationWebsiteUrl =
          values[CreateEducationHistoryFormField.OrganizationWebsiteUrl]
        const organizationLinkedInUrl =
          values[CreateEducationHistoryFormField.OrganizationLinkedInUrl]
        const organizationTwitterUrl =
          values[CreateEducationHistoryFormField.OrganizationTwitterUrl]
        const organizationFacebookUrl =
          values[CreateEducationHistoryFormField.OrganizationFacebookUrl]
        const startDate = parse(
          values[CreateEducationHistoryFormField.StartDate],
          'MM/yyyy',
          new Date(),
        )
        const endDate = values[CreateEducationHistoryFormField.EndDate]
          ? parse(
              values[CreateEducationHistoryFormField.EndDate],
              'MM/yyyy',
              new Date(),
            )
          : null
        let organizationId = !organization.isNew
          ? organization?.value
          : undefined

        const isEndDateBeforeStartDate = endDate && isBefore(endDate, startDate)

        if (isEndDateBeforeStartDate) {
          throw new Error(t('form.startDate.validation.beforeEndDate'))
        }

        if (isFuture(startDate)) {
          throw new Error(t('form.startDate.validation.future'))
        }

        if (organization.isNew) {
          if (
            !organizationWebsiteUrl &&
            !organizationLinkedInUrl &&
            !organizationTwitterUrl &&
            !organizationFacebookUrl
          ) {
            throw new Error(t('form.organization.validation.socialUrlRequired'))
          }
        }

        if (organization && organization.isNew) {
          const organizationResult = await createOrganization({
            variables: {
              input: {
                name: organization.value,
                websiteUrl: organizationWebsiteUrl,
                linkedInUrl: organizationLinkedInUrl,
                twitterUrl: organizationTwitterUrl,
                facebookUrl: organizationFacebookUrl,
              },
            },
          })

          if (organizationResult.data?.createOrganization.id) {
            organizationId = organizationResult.data.createOrganization.id
          }
        }

        if (!organizationId) {
          throw new Error('organizationId is required')
        }

        await createCommunityUserEducationHistory({
          variables: {
            input: {
              communityUserId: user.communityUserId,
              organizationId,
              startDate: format(startDate, 'yyyy-MM-dd'),
              endDate: endDate ? format(endDate, 'yyyy-MM-dd') : null,
              degrees: values[CreateEducationHistoryFormField.Degrees]
                ? values[CreateEducationHistoryFormField.Degrees]
                    .map(degree => degree.name)
                    .filter(Boolean)
                : null,
              majors: values[CreateEducationHistoryFormField.Majors]
                ? values[CreateEducationHistoryFormField.Majors]
                    .map(major => major.name)
                    .filter(Boolean)
                : null,
              minors: values[CreateEducationHistoryFormField.Minors]
                ? values[CreateEducationHistoryFormField.Minors]
                    .map(minor => minor.name)
                    .filter(Boolean)
                : null,
            },
          },
        })

        EventBus.trigger(EventBus.actions.profile.reload)

        // TODO: update graph

        toast.success({
          title: t('toast.title'),
          text: t('toast.success'),
        })

        onClose?.(true)
      } catch (error) {
        let message = _('error.generic')

        if (error instanceof Error) {
          message = error.message
        }

        toast.error({
          title: t('toast.title'),
          text: message,
        })
      } finally {
        setIsLoading(false)
      }
    },
    [onClose, t, createOrganization, createCommunityUserEducationHistory, user],
  )

  const renderForm = useCallback(
    ({
      handleSubmit,
      valid,
      values,
    }: FormRenderProps<CreateEducationHistoryFormValues>) => {
      const isCreatingOrganization =
        values[CreateEducationHistoryFormField.Organization]?.isNew

      return (
        <Modal
          cancelText={t('form.cancel')}
          confirmDisabled={isLoading || !valid}
          confirmText={t('form.submit')}
          isOpen
          title={t('title')}
          width="456px"
          onClose={() => onClose?.(false)}
          onConfirm={handleSubmit}
        >
          <Column gap={3}>
            <OrganizationField
              isRequired
              label={t('form.organization.label')}
              name={CreateEducationHistoryFormField.Organization}
              placeholder={t('form.organization.placeholder')}
            />

            {isCreatingOrganization && (
              <>
                <InputField
                  checkErrorIfDirty
                  clearable
                  label={t('form.organizationWebsiteUrl.label')}
                  name={CreateEducationHistoryFormField.OrganizationWebsiteUrl}
                />

                <InputField
                  checkErrorIfDirty
                  clearable
                  label={
                    <Row alignItems="center" gap={2}>
                      {t('form.organizationLinkedInUrl.label')}
                      <Icon.LinkedIn size={10} />
                    </Row>
                  }
                  name={CreateEducationHistoryFormField.OrganizationLinkedInUrl}
                />

                <InputField
                  checkErrorIfDirty
                  clearable
                  label={
                    <Row alignItems="center" gap={2}>
                      {t('form.organizationTwitterUrl.label')}
                      <Icon.Twitter size={10} />
                    </Row>
                  }
                  name={CreateEducationHistoryFormField.OrganizationTwitterUrl}
                />

                <InputField
                  checkErrorIfDirty
                  clearable
                  label={
                    <Row alignItems="center" gap={2}>
                      {t('form.organizationFacebookUrl.label')}
                      <Icon.Facebook size={10} />
                    </Row>
                  }
                  name={CreateEducationHistoryFormField.OrganizationFacebookUrl}
                />
              </>
            )}

            <InputArrayField
              addLabel={t('form.degrees.add.label')}
              label={t('form.degrees.label')}
              name={CreateEducationHistoryFormField.Degrees}
              removeLabel={t('form.degrees.remove.label')}
            />

            <InputArrayField
              addLabel={t('form.majors.add.label')}
              label={t('form.majors.label')}
              name={CreateEducationHistoryFormField.Majors}
              removeLabel={t('form.majors.remove.label')}
            />

            <InputArrayField
              addLabel={t('form.minors.add.label')}
              label={t('form.minors.label')}
              name={CreateEducationHistoryFormField.Minors}
              removeLabel={t('form.minors.remove.label')}
            />

            <Row gap={6} spaceBetween>
              <Column flex={1} gap={2}>
                <DateInputField
                  checkErrorIfDirty
                  clearable
                  label={t('form.startDate.label')}
                  name={CreateEducationHistoryFormField.StartDate}
                  required
                />
              </Column>

              <Column flex={1} gap={2}>
                <DateInputField
                  checkErrorIfDirty
                  clearable
                  label={t('form.endDate.label')}
                  name={CreateEducationHistoryFormField.EndDate}
                />
              </Column>
            </Row>
          </Column>
        </Modal>
      )
    },
    [isLoading, onClose, t],
  )

  return (
    <Form<CreateEducationHistoryFormValues>
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
      }}
      render={renderForm}
      validate={values =>
        validate(values, createEducationHistoryFormConstraints)
      }
      onSubmit={submit}
    />
  )
}

export default CreateEducationHistoryModal

import React from 'react'

import { CalendarIcon } from './DateInput.styles'

import { Input } from '../Input'
import { IInput } from '../Input/Input'

export interface DateInputProps extends IInput {}

const MONTH_DATE_SEPARATOR = '/'
const MONTH_DATE_REGEX =
  /^(0[1-9]|1[0-2]|0(?!\/)|1(?!\/))?(\/([0-9]{0,4})?)?$|^(0[1-9]|1[0-2])\/?$|^(0[1-9]|1[0-2])\/[0-9]{0,4}$/

export default function DateInput({ onChange, ...rest }: DateInputProps) {
  const ref = React.useRef<HTMLInputElement>(null)
  const lastValidValue = React.useRef<string>('')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!ref.current) {
      return
    }

    let { value } = event.target

    if (!MONTH_DATE_REGEX.test(value)) {
      value = lastValidValue.current
    }

    if (
      value.length === 2 &&
      !lastValidValue.current.includes(MONTH_DATE_SEPARATOR)
    ) {
      value += MONTH_DATE_SEPARATOR
    } else if (
      value.length === 2 &&
      lastValidValue.current.includes(MONTH_DATE_SEPARATOR)
    ) {
      value = value.slice(0, 1)
    }

    lastValidValue.current = value
    ref.current.value = value
    onChange?.(event)
  }

  return (
    <Input
      {...rest}
      placeholder="MM/YYYY"
      ref={ref}
      renderAfterElement={() => (
        <CalendarIcon color="#757575" height={18} width={18} />
      )}
      onChange={handleChange}
    />
  )
}

import React from 'react'
import { MultiValue } from 'react-select'

import { useApolloClient, useMutation } from '@apollo/client'
import debounce from 'awesome-debounce-promise'
import connectUsersToTagsMutation from 'GraphQL/Mutations/Community/connectUsersToTags.graphql'
import createTagsMutation from 'GraphQL/Mutations/Tag/createTags.graphql'
import listTagsQuery from 'GraphQL/Queries/listTags.graphql'
import { getCommunityUserTagsUpdater } from 'GraphQL/Updaters/GetCommunityUserTags'
import { setMinSearchLength } from 'Utils/Form'
import { ITagOptionInput, tagsToOptions } from 'Utils/Options'

import { Select, Tag } from 'Components/UI'

import { DEFAULT_MIN_SEARCH_SIZE, DEFAULT_SEARCH_DEBOUNCE } from 'Constants/ids'
import { TagBlockKind } from 'Constants/tags'

import { useCommunityContext } from 'Hooks'

import EventBus from 'Services/EventBus'
import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import Action from './Action'

import BlockTitle from '../BlockTitle'

export interface ITagsProps {
  icon?: React.ReactNode
  kind?: TagBlockKind
  title: string
  targetUser?: MainSchema.GraphUser | MainSchema.CommunityUser
}

const Tags: React.FC<ITagsProps> = ({
  icon = undefined,
  kind = undefined,
  title,
  targetUser = undefined,
}) => {
  const { community } = useCommunityContext()
  const client = useApolloClient()
  const s = useScopedI18n('community.communityUserTag')

  const [tagsToCreate, setTagsToCreate] = React.useState<
    MainSchema.CreateTagInput[]
  >([])

  const [existingTags, setExistingTags] = React.useState<MainSchema.Tag[]>([])

  const [isLoading, setLoading] = React.useState(false)

  const [connectUsersToTags] = useMutation(connectUsersToTagsMutation)
  const [createTags] = useMutation(createTagsMutation)

  const loadTagsOptions = React.useCallback(
    async (
      inputValue: string,
      callback: (options: ITagOptionInput[]) => void,
    ) => {
      try {
        const result = await client.query({
          query: listTagsQuery,
          variables: {
            communityId: community?.id,
            kind,
            search: inputValue,
            limit: 25,
          },
          fetchPolicy: 'network-only',
        })

        const tags = result?.data?.listTags?.rows ?? []
        callback(tagsToOptions(tags))
      } catch (error: any) {
        toast.error({
          title: s('errorTitle'),
          text: error.message,
        })
      }
    },
    [client, community?.id, kind, s],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedLoadOptions = React.useCallback(
    setMinSearchLength(
      debounce(loadTagsOptions, DEFAULT_SEARCH_DEBOUNCE),
      DEFAULT_MIN_SEARCH_SIZE,
    ),
    [loadTagsOptions],
  )

  const handleSubmit = React.useCallback(async () => {
    setLoading(true)

    let createdTags: MainSchema.Tag[] = []

    try {
      // If we need to create tags, do so
      if (tagsToCreate.length > 0) {
        const createTagsResponse = await createTags({
          variables: { tags: tagsToCreate },
        })
        createdTags = createTagsResponse?.data?.createTags || []
      }

      // Combine the existing tags with the newly created tags and connect them to the user
      const combinedTags = [...existingTags, ...createdTags]

      // Map to our graphql input type
      const usersToTags = combinedTags.map(tag => ({
        tagId: tag.id!,
        communityUserId: targetUser?.communityUserId!,
      })) as MainSchema.ConnectUsersToTagsInputType[]

      // Prepare the community user data for the updater
      const communityUsers = [
        {
          communityUserId: targetUser?.communityUserId!,
          userId: targetUser?.userId!,
          communityId: community?.id!,
        },
      ]

      // Connect the tags to the user, and update the cache
      await connectUsersToTags({
        variables: {
          communityId: community?.id,
          usersToTags,
        },
        update: getCommunityUserTagsUpdater({
          communityUsers,
          tags: combinedTags,
        }),
      })

      // Add the tags to the graph and create the edges
      combinedTags.forEach(tag => {
        // Add tag to graph
        EventBus.trigger(EventBus.actions.graph.addSkillTags, {
          id: tag.id,
          name: tag.name,
          kind: tag.kind,
          userId: targetUser?.userId,
        })

        // Connect tag to user on graph
        EventBus.trigger(EventBus.actions.graph.connectSkillTag, {
          fromId: targetUser?.userId,
          toId: tag.id,
          kind: tag.kind,
        })
      })

      toast.success({
        title: s('title'),
        text: s('createSuccess'),
      })
    } catch (error: any) {
      toast.error({
        title: s('errorTitle'),
        text: error?.message,
      })
    } finally {
      setExistingTags([])
      setTagsToCreate([])
      setLoading(false)
    }
  }, [
    tagsToCreate,
    existingTags,
    targetUser?.communityUserId,
    targetUser?.userId,
    community?.id,
    connectUsersToTags,
    s,
    createTags,
  ])

  const renderMultiValue = React.useCallback(
    (selectProps: any) => (
      <Tag
        colorKind={kind!}
        removable
        small
        text={selectProps?.children?.props?.text ?? selectProps?.children}
        onRemove={() => selectProps?.removeProps?.onClick()}
      />
    ),
    [kind],
  )

  const handleIsValidNewOption = React.useCallback((inputValue: string) => {
    return inputValue?.length >= 3
  }, [])

  const handleOnChange = React.useCallback(
    (newValue: MultiValue<ITagOptionInput>) => {
      const selectedTags = newValue.map(tag => ({ ...tag }))
      // Filter newly added tags that need to be created
      const tagsToCreate = selectedTags
        .filter(options => !options.id)
        .map(tag => ({
          name: tag?.value?.trim(),
          communityId: community?.id,
          kind,
        })) as MainSchema.CreateTagInput[]
      setTagsToCreate(tagsToCreate)

      // Filter the existing tags in the NOS system that just need to be appended to the user
      const existingTags = selectedTags
        .filter(options => options.id)
        .map(tag => ({
          id: tag?.id,
          name: tag?.name,
          kind,
        })) as MainSchema.Tag[]
      setExistingTags(existingTags)
    },
    [community?.id, kind],
  )

  return (
    <Action
      disabled={!tagsToCreate?.length && !existingTags?.length}
      isLoading={isLoading}
      title={<BlockTitle icon={icon} title={title} />}
      onSave={handleSubmit}
    >
      <Select
        async
        components={{ MultiValue: renderMultiValue }}
        creatable
        isMulti
        isValidNewOption={handleIsValidNewOption}
        loadOptions={debouncedLoadOptions}
        mt={2}
        placeholder={s('placeholder')}
        onChange={handleOnChange}
      />
    </Action>
  )
}

export default Tags

export const PERMISSION_SUBJECT = {
  NOTE: 'note',
  ADMIN_ONBOARDING: 'adminOnboarding',
  ADMIN_POLICY: 'adminPolicy',
  ACCOUNT: 'account',
  API_KEY: 'apiKey',
  INVITE: 'invite',
  HUBSPOT: 'hubspot',
  SHARED_NOTE: 'sharedNote',
  SHARED_USER: 'sharedUser',
  METRICS: 'metrics',
  UPLOAD_DATA: 'uploadData',
  MANAGEMENT_TAG: 'managementTag',
  MANAGEMENT_SKILL: 'managementSkill',
  MANAGEMENT_RBA: 'managementRba',
  MANAGEMENT_INDUSTRIES_TAG: 'managementIndustriesTag',
}

export const PERMISSION_ACTION = {
  READ: 'read',
  CREATE: 'create',
  EDIT: 'edit',
  DELETE: 'delete',
}

export const PERMISSION_SCOPE = {
  OWN: 'own',
  OTHER: 'other',
  ME: 'me',
  PRIVATE: 'private',
  PUBLIC: 'public',
  TARGET: 'target',
}

export const PERMISSION_LABEL = {
  // API KEYS
  API_KEYS: 'apiKey',

  // NOTES
  OWN_PUBLIC: 'ownPublic',
  OWN_PRIVATE: 'ownPrivate',
  OWN_ABOUT_ME_PUBLIC: 'ownAboutMePublic',
  OWN_ABOUT_ME_PRIVATE: 'ownAboutMePrivate',
  OTHER_ABOUT_ME_PUBLIC: 'otherAboutMePublic',
  OTHER_PUBLIC: 'otherPublic',

  // Management ACCOUNT
  MY_ACCOUNT: 'myAccount',
  OWN_ACCOUNT: 'ownAccount',
  OTHER_ACCOUNT: 'otherAccount',
  PRIVATE_ACCOUNT: 'privateAccount',
  // Management ADMIN
  ADMIN_ONBOARDING: 'adminOnboarding',
  ADMIN_POLICY: 'adminPolicy',
  // Users Invite
  USERS_INVITE: 'invite',
  SHARED_USERS: 'sharedUser',
  // Management HUBSPOT
  OWN_HUBSPOT: 'ownHubSpot',
  OTHER_HUBSPOT: 'otherHubSpot',

  METRICS: 'metrics',

  UPLOAD_DATA: 'uploadData',

  MANAGEMENT_TAG: 'managementTag',
  MANAGEMENT_SKILL: 'managementSkill',
  MANAGEMENT_RBA: 'managementRba',
  MANAGEMENT_INDUSTRIES_TAG: 'managementIndustriesTag',
}

export const PERMISSION_SCOPES = {
  NOTES_OWN_PUBLIC: [PERMISSION_SCOPE.OWN, PERMISSION_SCOPE.PUBLIC],
  NOTES_OWN_PRIVATE: [PERMISSION_SCOPE.OWN, PERMISSION_SCOPE.PRIVATE],
  NOTES_CREATOR_AND_TARGET_PUBLIC: [
    PERMISSION_SCOPE.OWN,
    PERMISSION_SCOPE.PUBLIC,
    PERMISSION_SCOPE.TARGET,
  ],
  NOTES_CREATOR_AND_TARGET_PRIVATE: [
    PERMISSION_SCOPE.OWN,
    PERMISSION_SCOPE.PRIVATE,
    PERMISSION_SCOPE.TARGET,
  ],
  NOTES_ABOUT_ME_PUBLIC: [
    PERMISSION_SCOPE.OTHER,
    PERMISSION_SCOPE.PUBLIC,
    PERMISSION_SCOPE.TARGET,
  ],
  NOTES_OTHER_CREATOR_PUBLIC: [PERMISSION_SCOPE.OTHER, PERMISSION_SCOPE.PUBLIC],
  NOTES_SHARED: [PERMISSION_SCOPE.OWN, PERMISSION_SCOPE.PRIVATE],

  // Account
  ACCOUNT_OWN_CREATOR: [PERMISSION_SCOPE.OWN],
  ACCOUNT_OTHER_CREATOR: [PERMISSION_SCOPE.OTHER],
  ACCOUNT_PRIVATE: [PERMISSION_SCOPE.PRIVATE],
}

import React, { useCallback, useState } from 'react'

import { useApolloClient, useMutation } from '@apollo/client'
import removeUserOAuthMutation from 'GraphQL/Mutations/User/removeUserOAuth.graphql'
import listOAuthProvidersQuery from 'GraphQL/Queries/User/listOAuthProviders.graphql'

import { Button, Checkbox } from 'Components/UI'

import { OAuthProvider } from 'Constants/mainGraphQL'
import { defaultOAuthScopes, oAuthScopes } from 'Constants/oAuthScopes'

import { useAppContext } from 'Hooks'

import _ from 'Services/I18n'
import toast from 'Services/Toast'

import oAuthUrlQuery from './oAuthUrl.graphql'
import { Wrapper } from './Scopes.styles'

interface Props {
  userOAuth: MainSchema.UserOAuth
}

const Scopes = (props: Props) => {
  const { userOAuth } = props
  const { me } = useAppContext()
  const client = useApolloClient()
  const initialScopes = userOAuth.scope ? userOAuth.scope.split(' ') : []
  const [removeUserOAuth] = useMutation(removeUserOAuthMutation)
  const [isButtonDisabled, setButtonDisabled] = useState(true)
  const [checkedScopes, setCheckedScopes] = useState(
    Object.fromEntries(
      Object.keys(oAuthScopes).map(key => [key, initialScopes.includes(key)]),
    ),
  )

  const disconnectUserOAuth = useCallback(
    async (userOAuth: MainSchema.UserOAuth) => {
      try {
        await removeUserOAuth({
          variables: {
            id: userOAuth.id,
            provider: userOAuth.provider,
          },
          refetchQueries: [listOAuthProvidersQuery],
        })
        toast.success({
          title: 'Remove connected account',
          text: 'Your connected account has been removed',
        })
      } catch (error) {
        let message = _('error.generic')

        if (error instanceof Error) {
          message = _(`error.${error.message}`)
        }

        toast.error({
          title: 'Remove connected account',
          text: <>{message}</>,
        })
      }
    },
    [removeUserOAuth],
  )

  const handleEdit = useCallback(
    async (userOAuthEmail: string) => {
      const selectedScopes = Object.keys(checkedScopes).filter(
        scope => checkedScopes[scope],
      )

      if (selectedScopes.length === 0) {
        disconnectUserOAuth(userOAuth)

        return
      }

      const result = await client.query({
        query: oAuthUrlQuery,
        fetchPolicy: 'network-only',
        variables: {
          provider: OAuthProvider.Google,
          options: {
            promptConsent: !!me?.id,
            loginHint: userOAuthEmail,
            scope: [...selectedScopes, ...defaultOAuthScopes],
          },
        },
      })

      const loginUrl = result.data?.oAuthUrl

      const state = {
        provider: OAuthProvider.Google,
        type: 'connect',
      }

      const urlEncodedState = encodeURIComponent(JSON.stringify(state))

      window.location.href = `${loginUrl}&state=${urlEncodedState}`

      setButtonDisabled(true)
    },
    [checkedScopes, client, disconnectUserOAuth, me?.id, userOAuth],
  )

  const handleScopeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    scopeKey: string,
  ) => {
    setCheckedScopes(prevScopes => {
      const newScopes = {
        ...prevScopes,
        [scopeKey]: event.target.checked,
      }
      const isSameAsInitial = Object.keys(newScopes).every(
        key => newScopes[key] === initialScopes.includes(key),
      )
      setButtonDisabled(isSameAsInitial)

      return newScopes
    })
  }

  return (
    <Wrapper>
      {Object.entries(oAuthScopes).map(([scopeKey, label]) => (
        <Checkbox
          checked={checkedScopes[scopeKey]}
          key={scopeKey}
          label={label}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleScopeChange(event, scopeKey)
          }
        />
      ))}
      <Button
        disabled={isButtonDisabled}
        secondary
        small
        type="button"
        onClick={() => handleEdit(userOAuth.email)}
      >
        Modify Permissions
      </Button>
    </Wrapper>
  )
}

export default Scopes

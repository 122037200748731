import {
  Glyph,
  Index,
  Item,
  ItemOrCombo,
  Items,
  Link,
  Node,
  SubItem,
} from 'regraph'
import {
  betweenness,
  closeness,
  degrees,
  eigenCentrality,
  pageRank,
} from 'regraph/analysis'
import { getOrganizationLogoUrl } from 'Utils/Organization'
import { resizeImageUrl } from 'Utils/Strings'

import cloneDeep from 'lodash/cloneDeep'
import concat from 'lodash/concat'
import forEach from 'lodash/forEach'
import omit from 'lodash/omit'

import { S3_URLS } from 'Config'

import {
  COMBO_NAME,
  ItemType,
  NODE_KIND,
  NODE_NAME,
  NodeKind,
  QuickActionKind,
  SkillTagKind,
} from 'Constants/graph'
import {
  RELATIONSHIP_STRENGTH_ENUM,
  USER_RELATIONSHIP_STRENGTH,
  UserRelationshipStrength,
} from 'Constants/ids'

import { IAvailableQuickAction } from 'Hooks/useGraphContext'

import _ from 'Services/I18n'

import { theme } from 'Theme'

import { IQuickActionKind } from '../QuickActions/constants'
import { IActionHandler } from '../QuickActions/useActionHandlers'

const DEFAULT_NODE_SIZE = 50

export const analyzerFunctions: Record<string, CallableFunction> = {
  betweenness,
  closeness,
  degrees,
  eigenCentrality,
  pageRank,
}

export type AnalyzerFunction = keyof typeof analyzerFunctions

/* *** PRIVATE HELPERS *** */
function createEdgeId(id1: string, id2: string) {
  return `edge:${id1}:${id2}`
}

function replaceImage(photoUrl: string) {
  let nextUrl = photoUrl

  forEach(S3_URLS, (value, key) => {
    nextUrl = nextUrl.replace(key, value)
  })

  return resizeImageUrl(nextUrl, '512')
}

/* *** CORE *** */

// TODO: keep in sync with IItemData properties
export const COMBO_PROPERTIES = ['cluster', 'clusterType', 'clusterContainer']

const getNodeSize = (node: Node) => {
  return node.size ?? 1
}

const isItemDataNode = (node: ItemOrCombo<IItemData>) => {
  return 'data' in node
}

export interface IItemData<TData = any, TEntity = any> {
  id: string
  type: ItemType
  // TODO: better name?
  data: TData
  entity?: TEntity
  // TODO: keep in sync with COMBO_PROPERTIES
  cluster?: string
  clusterType?: string
  clusterContainer?: string
}

export interface IUserNodeData {
  id: string
  firstName?: string
  lastName?: string
  email?: string
  photoUrl?: string
}

const isUserNode = (node: Node<IItemData>) => {
  return isItemDataNode(node) && node.data!.type === ItemType.User
}

const getAsUserNode = (node: Node<IItemData>) => {
  return node as Node<IItemData<IUserNodeData, MainSchema.GraphUser>>
}

const isUserQuickConnectGlyph = (
  node: ItemOrCombo<IItemData> | null,
  subItem: SubItem | null,
) => {
  return (
    node &&
    'data' in node &&
    isUserNode(node) &&
    node.data.entity &&
    subItem &&
    subItem.type === 'glyph' &&
    subItem.index === 0
  )
}

function createUserNode({
  userData,
  user,
  isMe,
  isSelected = false,
}: {
  userData: IUserNodeData
  user?: MainSchema.GraphUser
  isMe?: boolean
  isSelected?: boolean
}): Node<IItemData<IUserNodeData, MainSchema.GraphUser>> {
  const text =
    `${userData.firstName || ''} ${userData.lastName || ''}`.trim() ||
    userData.email
  const glyphs: Glyph[] = []

  // TODO: use quick actions to determine
  if (isSelected && user) {
    glyphs.push({
      image: '/img/quick-connector.svg',
      radius: 34,
      angle: 0,
      size: 1,
      position: 's',
    })
  }

  // If the user entity is not present we assume the user no longer exists.
  // This adds a visual indicator that the user is no longer valid
  if (!user) {
    glyphs.push({
      image: '/img/invalid.svg',
      radius: 44,
      angle: 0,
      size: 1,
    })
  }

  const userImagePath = isMe ? '/img/nodes/user.svg' : '/img/nodes/contact.svg'
  const nodeSize = isMe ? 1.5 : 1

  return {
    color: isMe ? '#CD1C45' : '#BBBFC2',
    shape: 'circle',
    cutout: true,
    size: isSelected ? 1.75 : nodeSize,
    // TODO: can the data be simplified?
    data: {
      id: userData.id!,
      type: ItemType.User,
      data: userData,
      entity: user,
    },
    glyphs,
    halos: [
      {
        color: '#2d2d2d',
        radius: 28,
        width: isSelected ? 3 : 0,
      },
      {
        color: '#1ccda4',
        radius: 32,
        width: isSelected ? 6 : 0,
      },
    ],
    label: [
      {
        backgroundColor: 'transparent',
        minWidth: 52,
        minHeight: 52,
        margin: 0,
        padding: 0,
      },
      {
        backgroundColor: 'transparent',
        padding: 2,
        margin: {
          top: isSelected ? 66 : 58,
          bottom: 0,
          left: 0,
          right: 0,
        },
        color: '#2d2d2d',
        fontFamily: 'Inter',
        fontSize: 10,
        bold: isSelected,
        text,
        position: {
          horizontal: 'center',
          vertical: 'top',
        },
      },
    ],
    image: userData.photoUrl ? replaceImage(userData.photoUrl) : userImagePath,
  }
}

export interface ISkillTagNodeData {
  id: string
  name: string
  kind: SkillTagKind
}

const isSkillTagNode = (node: Node<IItemData>) => {
  return isItemDataNode(node) && node.data!.type === ItemType.SkillTag
}

const getAsSkillTagNode = (node: Node<IItemData>) => {
  return node as Node<IItemData<ISkillTagNodeData>>
}

function createSkillTagNode({
  skillTagData,
  skillTag,
  isSelected,
}: {
  skillTagData: ISkillTagNodeData
  skillTag?: ISkill | ITag
  isSelected: boolean
}): Node<IItemData<ISkillTagNodeData>> {
  const borderWidth = 1.5
  const iconSize = 30
  const borderToTextGap = 8

  return {
    data: {
      id: skillTagData.id,
      type: ItemType.SkillTag,
      data: skillTagData,
      entity: skillTag,
      cluster: `${skillTagData.kind}_${COMBO_NAME[skillTagData.kind]}`,
    },
    color: isSelected ? '#ffffff' : '#F9FAFB',
    shape: 'circle',
    size: isSelected ? 1.75 : 1,
    border: {
      color: isSelected ? nodeKindColor(skillTagData.kind) : 'transparent',
      width: borderWidth,
    },
    halos: [
      {
        color: nodeKindLightColor(skillTagData.kind),
        radius: 28,
        width: isSelected ? 8 : 0,
      },
    ],
    image: nodeImage(skillTagData.kind),
    label: [
      {
        backgroundColor: 'transparent',
        color: isSelected ? '#ffffff' : nodeKindColor(skillTagData.kind),
        fontFamily: 'GraphIcons',
        fontSize: iconSize,
        position: {
          horizontal: 'center',
          vertical: 'middle',
        },
        padding: {
          top: iconSize / 2,
        },
      },
      {
        backgroundColor: 'transparent',
        margin: {
          top: DEFAULT_NODE_SIZE + borderWidth * 2 + borderToTextGap,
          bottom: 0,
          left: 0,
          right: 0,
        },
        color: '#2D2D2D',
        fontFamily: 'Inter',
        fontSize: 14,
        bold: true,
        text: skillTagData.name,
        textWrap: 'normal',
        minWidth: 200,
        position: {
          horizontal: 'center',
          vertical: 'top',
        },
      },
      {
        backgroundColor: 'transparent',
        color: '#757575',
        fontFamily: 'Inter',
        fontSize: 12,
        text: NODE_NAME[skillTagData.kind],
        textWrap: 'normal',
        minWidth: 200,
      },
    ],
  }
}
export interface IOrganizationNodeData {
  id: string
  name: string
  logos?: {
    url: string
    width: number
    height: number
  }[]
  linkedInUrl?: string
  twitterUrl?: string
  facebookUrl?: string
  websiteUrl?: string
  description?: string
  location?: {
    address1?: string
    address2?: string
    locality?: string
    region?: string
    postalCode?: string
    country?: string
  }
}

const isOrganizationNode = (node: Node<IItemData>) => {
  return isItemDataNode(node) && node.data!.type === ItemType.Organization
}

const getAsOrganizationNode = (node: Node<IItemData>) => {
  return node as Node<IItemData<IOrganizationNodeData>>
}

// TODO: Can this be merged with createSkillTag?
function createOrganizationNode({
  organizationData,
  organization,
  isSelected,
}: {
  organizationData: IOrganizationNodeData
  organization?: MainSchema.Organization
  isSelected: boolean
}): Node<IItemData<IOrganizationNodeData>> {
  const borderWidth = 1.5
  const iconSize = 30
  const borderToTextGap = 8
  const kind = NODE_KIND.organization

  const logoUrl = getOrganizationLogoUrl(organization?.logos)

  return {
    data: {
      id: organizationData.id,
      type: ItemType.Organization,
      data: organizationData,
      entity: organization,
      cluster: `${kind}_${COMBO_NAME[kind]}`,
    },
    color: isSelected ? '#ffffff' : '#F9FAFB',
    shape: 'circle',
    cutout: true,
    border: {
      color: isSelected ? nodeKindColor(kind) : 'transparent',
      width: borderWidth,
    },
    halos: [
      {
        color: nodeKindLightColor(kind),
        radius: 28,
        width: isSelected ? 8 : 0,
      },
    ],
    image: logoUrl ?? nodeImage(kind),
    label: [
      {
        backgroundColor: 'transparent',
        color: isSelected ? '#ffffff' : nodeKindColor(kind),
        fontFamily: 'GraphIcons',
        fontSize: iconSize,
        position: {
          horizontal: 'center',
          vertical: 'middle',
        },
        padding: {
          top: iconSize / 2,
        },
      },
      {
        backgroundColor: 'transparent',
        margin: {
          top: DEFAULT_NODE_SIZE + borderWidth * 2 + borderToTextGap,
          bottom: 0,
          left: 0,
          right: 0,
        },
        color: '#2D2D2D',
        fontFamily: 'Inter',
        fontSize: 14,
        bold: true,
        text: organizationData.name,
        textWrap: 'normal',
        minWidth: 200,
        position: {
          horizontal: 'center',
          vertical: 'top',
        },
      },
      {
        backgroundColor: 'transparent',
        color: '#757575',
        fontFamily: 'Inter',
        fontSize: 12,
        text: NODE_NAME[kind],
        textWrap: 'normal',
        minWidth: 200,
      },
    ],
  }
}

export interface IEdgeData {
  fromId: string
  toId: string
  kind?: NodeKind
  fade?: boolean
}

function createEdge({
  edgeData,
}: {
  edgeData: IEdgeData
}): Link<IItemData<IEdgeData>> {
  const id = createEdgeId(edgeData.fromId, edgeData.toId)

  return {
    id1: edgeData.fromId,
    id2: edgeData.toId,
    lineStyle: 'solid',
    width: 0.6,
    color: '#2D2D2D',
    fade: true,
    data: {
      id,
      type: ItemType.Edge,
      data: edgeData,
    },
  }
}

/* *** PRIVATE *** */

const RELATIONSHIP_STRENGTH_EDGE_COLORS: Record<string, string> = {
  [RELATIONSHIP_STRENGTH_ENUM.dontKnow]: '#CECECE',
  [RELATIONSHIP_STRENGTH_ENUM.weak]: '#A9A9A9',
  [RELATIONSHIP_STRENGTH_ENUM.moderate]: '#787878',
  [RELATIONSHIP_STRENGTH_ENUM.strong]: '#2D2D2D',
}

const RELATIONSHIP_STRENGTH_EDGE_GLYPH_COLORS: Record<string, string> = {
  [RELATIONSHIP_STRENGTH_ENUM.dontKnow]: '#D2D2D3',
  [RELATIONSHIP_STRENGTH_ENUM.weak]: '#B1B1B1',
  [RELATIONSHIP_STRENGTH_ENUM.moderate]: '#858585',
  [RELATIONSHIP_STRENGTH_ENUM.strong]: '#2B2B2B',
}

export interface IRelationshipStrengthEdgeData {
  fromId: string
  toId: string
  strength: number
  fade?: boolean
}

function createRelationshipStrengthEdge({
  relationshipStrengthEdgeData,
}: {
  relationshipStrengthEdgeData: IRelationshipStrengthEdgeData
}): Link<IItemData<IRelationshipStrengthEdgeData>> {
  const color =
    RELATIONSHIP_STRENGTH_EDGE_COLORS[relationshipStrengthEdgeData.strength]
  const glyphColor =
    RELATIONSHIP_STRENGTH_EDGE_GLYPH_COLORS[
      relationshipStrengthEdgeData.strength
    ]

  return {
    id1: relationshipStrengthEdgeData.fromId,
    id2: relationshipStrengthEdgeData.toId,
    color,
    lineStyle: 'solid',
    width: 0.75,
    fade: relationshipStrengthEdgeData.fade ?? true,
    glyphs: [
      {
        border: {
          color,
        },
        color: 'rgba(255,255,255,1)',
        size: 0,
        label: {
          fontFamily: 'Inter',
          color: glyphColor,
          text: `${relationshipStrengthEdgeData.strength}`,
        },
      },
    ],
    data: {
      id: createEdgeId(
        relationshipStrengthEdgeData.fromId,
        relationshipStrengthEdgeData.toId,
      ),
      type: ItemType.RelationshipStrengthEdge,
      data: relationshipStrengthEdgeData,
    },
  }
}

/* *** PUBLIC *** */

// TODO: Temporarily making public to fix a bug on prod
function nodeKindColor(kind: NodeKind, defaultColor?: string) {
  return theme.graph.nodeKind[kind] || defaultColor
}

function nodeKindLightColor(kind: NodeKind) {
  return theme.graph.nodeKindLight[kind] || '#dfdfdf'
}

function nodeImage(kind: NodeKind) {
  return `/img/nodes/${kind}.svg`
}

function isCombo(id?: string | null) {
  return id?.startsWith('_combonode_')
}

function isEdge(id?: string) {
  return id?.startsWith('edge')
}

export interface ISkill {
  id: string
  name?: string | null
}

export interface ITag {
  id: string
  name: string
  kind: SkillTagKind
}

function appendItems({
  me,
  users = [],
  skills = [],
  needSkills = [],
  tags = [],
  organizations = [],
  showTargetTags = false,
  showTargetSkills = false,
  showTargetOrganizations = false,
  existingItems = {},
  selectedIds = [],
}: {
  me?: MainSchema.User
  users?: MainSchema.GraphUser[]
  skills?: ISkill[]
  needSkills?: ISkill[]
  tags?: ITag[]
  organizations?: MainSchema.Organization[]
  showTargetTags?: boolean
  showTargetSkills?: boolean
  showTargetOrganizations?: boolean
  existingItems?: Items<IItemData>
  selectedIds: string[]
}): [Index<Item<IItemData>>, Index<Link<IItemData>>] {
  const graphNodes: Index<Item<IItemData>> = {}
  const graphEdges: Index<Link<IItemData>> = {}

  const appendedTags = appendSkillsTagsOrganizations({
    skills,
    needSkills,
    tags,
    organizations,
    selectedIds,
  })

  Object.assign(graphNodes, appendedTags)

  forEach(users, user => {
    // temporary FE solution to ignore archived users
    if (user && user.id && user.communityUserStatus !== 'archived') {
      const userNode = createUserNode({
        userData: {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          photoUrl: user.photoUrl,
        },
        user,
        isMe: user.id === me?.id,
        isSelected: selectedIds.includes(user.id),
      })

      if (userNode.data!.id) {
        graphNodes[userNode.data!.id] = userNode
      }

      if (existingItems[user.id]) {
        return
      }

      const userSkills = concat(user.skills as MainSchema.Skill[])
      const userNeedSkills = concat(user.needSkills as MainSchema.Skill[])
      const userTags = concat(user.tags as MainSchema.Tag[])

      let skillsTagsOrganizations = {}

      if (showTargetSkills) {
        const appendSkills = appendSkillsTagsOrganizations({
          skills: userSkills,
          selectedIds,
        })

        skillsTagsOrganizations = {
          ...skillsTagsOrganizations,
          ...appendSkills,
        }
      }

      if (showTargetTags) {
        const appendTags = appendSkillsTagsOrganizations({
          tags: userTags,
          selectedIds,
        })

        skillsTagsOrganizations = {
          ...skillsTagsOrganizations,
          ...appendTags,
        }
      }

      if (showTargetOrganizations) {
        const organizations = user.workHistory
          ?.filter(workHistory => workHistory.isPrimary)
          .map(
            workHistory => workHistory.organization,
          ) as MainSchema.Organization[]

        skillsTagsOrganizations = {
          ...skillsTagsOrganizations,
          ...appendSkillsTagsOrganizations({
            organizations,
            selectedIds,
          }),
        }
      }

      forEach(skillsTagsOrganizations, (item, id) => {
        graphNodes[id] = item
      })

      forEach(user.connectedUsers, (_, connectionId) => {
        // TODO: Fix arrow edges
        if (user.id) {
          const [fromId, toId] = [user.id, connectionId].sort()
          const edge = createEdge({
            edgeData: {
              fromId,
              toId,
              kind: NODE_KIND.connection,
            },
          })
          graphEdges[edge.data!.id] = edge
        }
      })

      forEach(userSkills, entity => {
        if (user.id && entity?.id) {
          const [fromId, toId] = [user.id, entity.id]

          const edge = createEdge({
            edgeData: {
              fromId,
              toId,
              kind: NODE_KIND.skill,
            },
          })

          graphEdges[edge.data!.id] = edge
        }
      })

      forEach(userNeedSkills, entity => {
        if (user.id && entity?.id) {
          const [fromId, toId] = [user.id, entity.id]

          const edge = createEdge({
            edgeData: {
              fromId,
              toId,
              kind: NODE_KIND.skill,
            },
          })

          graphEdges[edge.data!.id] = edge
        }
      })

      forEach(userTags, entity => {
        if (user.id && entity?.id) {
          const [fromId, toId] = [user.id, entity.id]

          const edge = createEdge({
            edgeData: {
              fromId,
              toId,
              kind: NODE_KIND.skill,
            },
          })

          graphEdges[edge.data!.id] = edge
        }
      })

      forEach(user.workHistory, workHistory => {
        if (!user.id || !workHistory.organization) {
          return
        }

        const [fromId, toId] = [user.id, workHistory.organization.id]

        const edge = createEdge({
          edgeData: {
            fromId,
            toId,
            kind: NODE_KIND.organization,
          },
        })

        graphEdges[edge.data!.id] = edge
      })

      forEach(user.educationHistory, educationHistory => {
        if (!user.id || !educationHistory.organization) {
          return
        }

        const [fromId, toId] = [user.id, educationHistory.organization.id]

        const edge = createEdge({
          edgeData: {
            fromId,
            toId,
            kind: NODE_KIND.organization,
          },
        })

        graphEdges[edge.data!.id] = edge
      })
    }
  })

  return [graphNodes, graphEdges]
}

function appendSkillsTagsOrganizations({
  skills,
  tags,
  organizations,
  selectedIds,
}: {
  skills?: ISkill[]
  needSkills?: ISkill[]
  tags?: ITag[]
  organizations?: MainSchema.Organization[] | null
  selectedIds: string[]
}) {
  const graphNodes: Items<IItemData> = {}
  forEach(skills, skill => {
    if (skill) {
      const newSkill = createSkillTagNode({
        skillTagData: {
          id: skill.id,
          name: skill?.name || 'N/A',
          kind: NODE_KIND.skill,
        },
        skillTag: skill,
        isSelected: selectedIds.includes(skill.id),
      })
      graphNodes[newSkill.data!.id] = newSkill
    }
  })

  forEach(tags, tag => {
    if (tag) {
      const newTag = createSkillTagNode({
        skillTagData: {
          id: tag.id,
          name: tag.name,
          kind: tag.kind,
        },
        skillTag: tag,
        isSelected: selectedIds.includes(tag.id),
      })
      graphNodes[newTag.data!.id] = newTag
    }
  })

  forEach(organizations, organization => {
    if (!organization) return
    const newOrganization = createOrganizationNode({
      organizationData: organization,
      organization,
      isSelected: selectedIds.includes(organization.id),
    })
    graphNodes[newOrganization.data!.id] = newOrganization
  })

  return graphNodes
}

// TODO: Optimize
function appendRelationshipStrengthEdges({
  me,
  nodes,
  relationships,
  edges,
  showRelationshipStrength,
}: {
  me: MainSchema.User
  nodes: Items<IItemData>
  relationships: Record<string, Record<string, UserRelationshipStrength>>
  edges: Index<Link<IItemData>>
  showRelationshipStrength?: Record<string, boolean>
}) {
  const result: Items = {}
  const omitEdgeIds: string[] = []

  forEach(nodes, (item, fromId) => {
    if (isUserNode(item)) {
      if (item.data!.id === me?.id) {
        // Ensure logged in user is always on the graph
        result[item.data!.id] = item
      }
      forEach(relationships[fromId], (strength, toId) => {
        if (showRelationshipStrength?.[strength]) {
          const id1 = edges[createEdgeId(fromId, toId)]
          const id2 = edges[createEdgeId(toId, fromId)]

          if (id1) omitEdgeIds.push(id1.data!.id)
          if (id2) omitEdgeIds.push(id2.data!.id)

          const edge = createRelationshipStrengthEdge({
            relationshipStrengthEdgeData: {
              fromId,
              toId,
              strength: RELATIONSHIP_STRENGTH_ENUM[strength],
            },
          })

          // TODO: Check why nodes[fromId], or  nodes[toId] can be undefined - existing relationship strength in the DB, but a user didn't exist anymore?
          if (nodes[fromId]) result[fromId] = nodes[fromId]
          if (nodes[toId]) result[toId] = nodes[toId]

          result[edge.data!.id] = edge
        }
      })
    } else {
      result[fromId] = item
    }
  })

  return { ...omit(edges, omitEdgeIds), ...result }
}

function buildPaths(
  paths: MainSchema.GraphUser[][],
  items: Index<Item<IItemData>>,
  me: MainSchema.User,
  selectedIds: string[],
) {
  const clonedItems = cloneDeep(items)
  let updatedItems: Index<Item<IItemData>> = {}
  const updatedEdges: Index<Link<IItemData>> = {}
  const nodeIds: string[] = []

  forEach(paths, path => {
    forEach(path, (currentNode, index) => {
      nodeIds.push(currentNode.id!)

      const userNode = createUserNode({
        userData: {
          id: currentNode.id!,
          firstName: currentNode.firstName,
          lastName: currentNode.lastName,
          email: currentNode.email,
          photoUrl: currentNode.photoUrl,
        },
        user: currentNode,
        isMe: currentNode.id === me?.id,
        isSelected: selectedIds.includes(currentNode.id!),
      })
      updatedItems = {
        ...updatedItems,
        [userNode.data!.id]: userNode,
      }

      const nextNode = path[index + 1]

      if (!nextNode) {
        return
      }

      const toEdgeId = createEdgeId(nextNode.id!, currentNode.id!)
      const fromEdgeId = createEdgeId(currentNode.id!, nextNode.id!)

      const hasToEdge = !!items[toEdgeId]

      const userEdge = createEdge({
        edgeData: {
          fromId: hasToEdge ? nextNode.id! : currentNode.id!,
          toId: hasToEdge ? currentNode.id! : nextNode.id!,
          fade: false,
        },
      })

      updatedEdges[hasToEdge ? toEdgeId : fromEdgeId] = userEdge
    })
  })

  forEach(clonedItems, item => {
    if (item.data!.id && !nodeIds.includes(item.data!.id)) {
      const fadedItem = cloneDeep(item)
      fadedItem.fade = true
      updatedItems[item.data!.id] = fadedItem
    }
  })

  return { ...updatedItems, ...updatedEdges }
}

function getClusteredName(item: IItemData, id: string) {
  return (
    item.cluster?.split('_')?.pop() ||
    item.clusterType?.split('_')?.pop() ||
    item.clusterContainer?.split('_')?.pop() ||
    id
  )
}

function getClusteredColor(item: IItemData) {
  const actionKind =
    item.cluster?.split('_')?.[0] ||
    item.clusterType?.split('_')?.[0] ||
    item.clusterContainer?.split('_')?.[0]

  return nodeKindColor(actionKind as NodeKind, '#cecece')
}

async function analyzeNodes(analyzer: AnalyzerFunction, nodes: Index<Node>) {
  const res = await analyzerFunctions[analyzer](nodes, {})

  const nodesCopy = cloneDeep(nodes)

  forEach(res, (value, key) => {
    nodesCopy[key].size = Math.max(Math.min(Number(value), 8), 0.5)
  })

  return nodesCopy
}

function deserializeUserRelationStrength(
  usersRelationStrength: string[],
): Record<string, Record<string, UserRelationshipStrength>> {
  return usersRelationStrength.reduce<
    Record<string, Record<string, UserRelationshipStrength>>
  >((acc, item) => {
    const [fromUserId, strength, toUserId] = item.split(':')
    const strengthNumber = parseInt(strength, 10)
    let id: UserRelationshipStrength = USER_RELATIONSHIP_STRENGTH.DONT_KNOW

    if (strengthNumber === 1) id = USER_RELATIONSHIP_STRENGTH.WEAK
    if (strengthNumber === 2) id = USER_RELATIONSHIP_STRENGTH.MODERATE
    if (strengthNumber === 3) id = USER_RELATIONSHIP_STRENGTH.STRONG

    if (!acc[fromUserId]) {
      acc[fromUserId] = { [toUserId]: id }
    } else {
      acc[fromUserId] = {
        ...acc[fromUserId],
        [toUserId]: id,
      }
    }

    return acc
  }, {})
}

function appendSkillTag({
  targetUserId,
  id,
  name,
  kind,
  isSelected,
}: {
  targetUserId: string
  id: string
  name: string
  kind: SkillTagKind
  isSelected: boolean
}): [Index<Item<IItemData>>, Index<Link<IItemData>>] {
  const node = createSkillTagNode({
    skillTagData: { id, name, kind },
    skillTag: { id, name, kind },
    isSelected,
  })
  const edge = createEdge({
    edgeData: {
      fromId: targetUserId,
      toId: id,
      kind,
    },
  })
  return [{ [node.data!.id]: node }, { [edge.data!.id]: edge }]
}

function createUserConnection({
  user,
  connectTo,
}: {
  user: MainSchema.GraphUser
  connectTo: MainSchema.GraphUser
}) {
  const [fromId, toId] = [user.id!, connectTo.id!].sort()
  const edge = createEdge({
    edgeData: {
      fromId,
      toId,
      kind: NODE_KIND.connection,
    },
  })
  return { [edge.data!.id]: edge }
}

function createTemporaryUserConnection({
  fromUserId,
  toUserId,
}: {
  fromUserId: string
  toUserId: string
}) {
  const [fromId, toId] = [fromUserId, toUserId].sort()
  const edge = createEdge({
    edgeData: {
      fromId,
      toId,
      kind: NODE_KIND.connection,
    },
  })
  return { [edge.data!.id]: edge }
}

function appendUserSkillTagEdge({
  fromId,
  toId,
  kind,
}: {
  fromId: string
  toId: string
  kind: NodeKind
}) {
  const edge = createEdge({
    edgeData: {
      fromId,
      toId,
      kind,
    },
  })
  return { [edge.data!.id]: edge }
}

function findExistingEdge(
  existing: Items<IItemData>,
  id1: string,
  id2: string,
) {
  return (
    existing[`edge:${id1}:${id2}`] || existing[`edge:${id2}:${id1}`] || null
  )
}
export interface IQuickAction {
  kind: QuickActionKind
  label: string
  icon: React.ReactNode
  handleClick: () => void
}

const getAvailableQuickActionsForUsers = (
  quickActions: QuickActionKind[],
  quickActionHandlers: Record<QuickActionKind, IActionHandler>,
  users: MainSchema.GraphUser[],
  selectedUsers: MainSchema.GraphUser[],
): Record<string, Record<string, IAvailableQuickAction>> => {
  const availableQuickActions: Record<
    string,
    Record<string, IAvailableQuickAction>
  > = {}
  forEach(users, user => {
    availableQuickActions[user.id!] = {}

    quickActions.forEach(kind => {
      const actionHandler = quickActionHandlers[kind]

      availableQuickActions[user.id!][kind] = {
        isEnabled: actionHandler.isEnabled({
          user,
          users: selectedUsers,
        }),
        handleClick: () =>
          actionHandler.onClick({
            user,
            users: selectedUsers,
          }),
      }
    })
  })

  return availableQuickActions
}

const getEnabledQuickActions = (
  quickActionKinds: IQuickActionKind[],
  availableQuickActions: Record<string, IAvailableQuickAction>,
): IQuickAction[] => {
  const quickActions: IQuickAction[] = []

  quickActionKinds.forEach(quickActionKind => {
    const availableQuickAction = availableQuickActions[quickActionKind.kind]

    if (!availableQuickAction?.isEnabled) {
      return
    }

    quickActions.push({
      kind: quickActionKind.kind,
      label: _(quickActionKind.label),
      icon: quickActionKind.icon,
      handleClick: availableQuickAction.handleClick,
    })
  })

  return quickActions
}

const utils = {
  getNodeSize,
  isItemDataNode,
  isUserNode,
  getAsUserNode,
  isUserQuickConnectGlyph,
  createUserNode,
  isSkillTagNode,
  getAsSkillTagNode,
  createSkillTagNode,
  isOrganizationNode,
  getAsOrganizationNode,
  createOrganizationNode,
  nodeKindColor,
  isCombo,
  isEdge,
  createEdge,
  createRelationshipStrengthEdge,
  appendItems,
  appendSkillTag,
  appendRelationshipStrengthEdges,
  buildPaths,
  createUserConnection,
  createTemporaryUserConnection,
  appendUserSkillTagEdge,
  getClusteredName,
  getClusteredColor,
  analyzeNodes,
  deserializeUserRelationStrength,
  findExistingEdge,
  getAvailableQuickActionsForUsers,
  getEnabledQuickActions,
}

export default utils
